'use strict';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Api } from '../../../logic/Api/Api';
import { RootState } from '../../../logic/store/Store';
import { chunk, groupBy, sortBy } from 'lodash';
import { IManageUserResponse } from '../../../logic/Models/Responses/ManageUsersResponse';
import { IGrowerResponse } from '../../../logic/Models/Responses/GrowerResponse';
import { IFarmResponse } from '../../../logic/Models/Responses/FarmResponse';
import { globalSession, useScopedSession, useSession } from '../../../tracing/session';

// TODO this references context, which no longer exists. We need to create a subscope here.

/**
 * A helper hook that tracks a user + their growers + the farms and a selection state across each.
 * @param startLoading Method to call when data is starting to load
 * @param endLoading Method to call when data completes loading
 * @param includeDeleted Whether to include deleted growers in the listing
 */
export function useSelectionState(startLoading: () => unknown, endLoading: () => unknown, includeDeleted: boolean, incrementProgress: () => unknown, addExpected: (items: number) => unknown) 
{
	const userAuthToken = useSelector((state: RootState) => state.auth.userAuthToken);
	const manageableUsers = useSelector((state: RootState) => state.auth.manageableUsers);
	const users = useMemo(() => sortBy(manageableUsers, mu => mu.UserName.toLocaleLowerCase()), [manageableUsers]);
	const session = useScopedSession(useSelectionState.name);

	const [user, setUser] = useState<IManageUserResponse>();
	const [growers, setGrowers] = useState<IGrowerResponse[]>();
	const [selectedGrower, setSelectedGrower] = useState<IGrowerResponse>();
	const [triggerRefresh, setTriggerRefresh] = useState(0);
	const [farmsByGrower, setFarmsByGrower] = useState<Record<string, IFarmResponse[]>>({});

	// When the user changes, fetch the growers
	useEffect(() => 
	{
		const controller = new AbortController();
		const { signal } = controller;
				
		async function execute() 
		{
			setGrowers([]);

			if (!user) 
			{
				return;
			}

			startLoading();
			try 
			{
				const api = new Api('/api/4', userAuthToken, session);
				const response = await api.getAsync<IGrowerResponse[]>(`users/${user.UserId}/growers?forceSlowUpdateFromCropEdge=true&includeDeleted=${includeDeleted ? 'true' : 'false'}`, {signal});
				if(!signal.aborted)
				{
					setGrowers(sortBy(response.Data, g => g.Name.toLocaleLowerCase()));
				}
			}
			catch (err) 
			{
				globalSession.error(err);
			}

			finally 
			{
				endLoading();
			}

		}
		execute();
		
		return () => { controller.abort(); };
	}, [userAuthToken, startLoading, endLoading, user?.UserId, includeDeleted, incrementProgress]);

	const selectUser = useCallback((userId: string | undefined) => 
	{
		setUser(userId ? users.find(u => u.UserId === userId) : undefined);
	}, [users]);

	const selectGrower = useCallback((growerId: string | undefined) => 
	{
		setSelectedGrower(growerId && growers ? growers.find(g => g.Id == growerId) : undefined);
	}, [growers]);

	// When the growers are updated, fetch a summary of all farms
	useEffect(() => 
	{
		// Clear existing farms
		setFarmsByGrower({});
		if (!growers?.length) 
		{
			return;
		}

		// fetch farm data across growers
		const controller = new AbortController();
		const { signal } = controller;

		async function addGrowerFarms() 
		{
			startLoading();

			try 
			{
				addExpected(growers.length);
				for(const growerChunk of chunk(growers, 4))
				{
					const tasks = growerChunk.map(async grower => 
					{
						const api = new Api('/api/4', userAuthToken, session);
						const response = await api.getAsync<IFarmResponse[]>(`users/${user.UserId}/growers/${grower.Id}/farm-profile?`
							+ 'include=[Id,GrowerId,Name,Fields[Id, Name, Area]]', { signal });

						incrementProgress();
						return [grower.Id, sortBy(response.Data, fm => fm.Name.toLocaleLowerCase())];
					});

					// Be done if a cancel was requested
					if(signal.aborted)
					{
						return;
					}

					const farms = await Promise.all(tasks);
					setFarmsByGrower((prev) => Object.fromEntries([...Object.entries(prev), ...farms]));
				}
			}
			catch (err) 
			{
				globalSession.error(err);
			}
			finally 
			{
				endLoading();
			}
		}

		addGrowerFarms();

		return () => { controller.abort(); };
	}, [growers, triggerRefresh, incrementProgress, addExpected]);

	const refresh = useCallback(() => setTriggerRefresh(prev => prev+1), []);

	return { users, user, growers, selectedGrower, farms: farmsByGrower, selectUser, selectGrower, refresh };
}
