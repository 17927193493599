import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { area, feature, Feature, featureCollection, FeatureCollection, Geometry, multiPolygon, MultiPolygon, Position } from '@turf/turf';
import { ISeedRateData, IZoneData } from '../../../pages/FieldActivities/VRS/VRSMain';
import { cutPolygons } from './ManagementZoneThunks';
import _ from 'lodash';
import { globalSession } from '../../../tracing/session';

export interface IZoneFeatureCollection extends FeatureCollection<MultiPolygon | Geometry, IZoneData> { }

/**
 * Represents the editable data for a management zone in the VRS workflow
 */
export interface IZoneEditingState
{
	/** The aggregate area of all zones */
	totalArea: number;
	/** A map of zones by id (number) to the hybrid list and the zone's aggregate area. */
	zones: { [key: string]: IZoneData };
	/** List of the distinct zones in this plan */
	zoneIds: number[];
	/** The current state of the geometry and polygon-specific data.  Modifications happen here. */
	editedFeatures: IZoneFeatureCollection;
}

// The different supported ways of interacting with a polygon/zone/field
export enum SelectionInteractionMode {
	Default,
	Selection,
	Painting,
	Cutting,
	CircleCutting,
	AddingZone
}

// Default map mode -- i.e. not in the management zone workflow
export interface ISelectionInteractionDefault {
	mode: SelectionInteractionMode.Default;
}

// Basic management zone workflow -- with no tool currently selected
export interface ISelectionInteractionSelection {
	mode: SelectionInteractionMode.Selection;
}
export interface ISelectionInteractionPainting {
	mode: SelectionInteractionMode.Painting;
	zone: number;
}
export interface ISelectionInteractionAddingZone {
	mode: SelectionInteractionMode.AddingZone;
}

export interface ISelectionInteractionCutting {
	mode: SelectionInteractionMode.Cutting;
}

export interface ISelectionInteractionCircleCutting {
	mode: SelectionInteractionMode.CircleCutting;
}

export type SelectionInteractionModes =
	| ISelectionInteractionDefault
	| ISelectionInteractionAddingZone
	| ISelectionInteractionPainting
	| ISelectionInteractionSelection
	| ISelectionInteractionCutting
	| ISelectionInteractionCircleCutting;

export interface IManagementZoneEditState
{
	/** Is there an error from the load/update */
	isError: boolean;
	/** The error message when there is an error from the load/update */
	errorMessage: string;
	/** Is data currently being loaded/updated */
	isLoading: boolean;
	/** The editable geometry as it currently stands. */
	zoneEditingState: IZoneEditingState;
	/** The history of edited geometry since the last save. */
	undoStack: IZoneEditingState[];
	/** What the current interaction mode is */
	mode: SelectionInteractionModes;
	selectedZone: number | undefined;
}

export const initialState: IManagementZoneEditState =
{
	isError: false,
	isLoading: false,
	errorMessage: undefined,	
	zoneEditingState: undefined,
	undoStack: [],
	mode: {
		mode: SelectionInteractionMode.Default
	},
	selectedZone: undefined,
};

export const managementZoneEditsSlice = createSlice({
	name: 'managementZoneEdits',
	initialState,
	reducers:
	{
		clearState: (state) => 
		{
			state.zoneEditingState = undefined;
			state.undoStack = [];
			state.mode = { mode: SelectionInteractionMode.Default };
			state.selectedZone = undefined;
			state.isError = false;
			state.isLoading = false;
			state.errorMessage = undefined;

			return state;
		},
		clearError: (state) =>
		{
			state.isError = false;
			state.errorMessage = undefined;
		},
		initializeZoneEditingState: (state, { payload }: PayloadAction<{zoneData: IZoneData[], geometry: FeatureCollection<Geometry>}>) =>
		{
			// Initialize the editing state with the existing zone data and geometry
			const { zoneData, geometry } = payload;

			const zoneEditingState: IZoneEditingState =
			{
				totalArea: 0,
				zones: {},
				zoneIds: [],
				editedFeatures: undefined,
			};

			const features: Feature<Geometry, IZoneData>[] = [];

			zoneData.forEach(zone =>
			{
				zoneEditingState.totalArea += zone.Acres;
				zoneEditingState.zones[zone.ZoneName] = zone;
				zoneEditingState.zoneIds.push(zone.ZoneName);

				// Find the specific feature associated with the zone
				const zoneGeometry = geometry.features.find(feature => feature.properties.zone === zone.ZoneName);
				features.push(feature(zoneGeometry.geometry, zone));
			});

			// Add the features into the collection
			zoneEditingState.editedFeatures = featureCollection(features);

			state.selectedZone = undefined;

			state.undoStack = [];

			state.zoneEditingState = zoneEditingState;

			return state;
		},
		setVRSMapInteractionMode: (state, { payload }: PayloadAction<SelectionInteractionModes>) =>
		{
			state.mode = payload;
			return state;
		},
		setSelectedZone: (state, { payload }: PayloadAction<number>) =>
		{
			// Second click so clear the selection
			if (state.selectedZone === payload)
			{
				state.selectedZone = undefined;
			}
			else
			{
				state.selectedZone = payload;
			}
			
			return state;
		},
		paintZone: (state, { payload }: PayloadAction<{ zone: number, polygonIndex: number }>) =>
		{
			// Paint a zone polygon with the color of the MapMode Painting selection-zone
			if (state.mode.mode === SelectionInteractionMode.Painting)
			{
				const destinationZone = state.mode.zone;
				const sourceZone = payload.zone;
				const sourcePolygon = payload.polygonIndex;

				// If the polygon index is less than zero, ignore it.
				// Or the selected Zone is undefined.
				// Or this is the same zone.
				if (sourcePolygon < 0 || destinationZone === undefined || destinationZone === sourceZone)
				{
					return state;
				}

				const currentEditingZones: IZoneFeatureCollection = state.zoneEditingState.editedFeatures;				

				const sourceFeatureIdx = currentEditingZones.features.findIndex(
					(feature) => feature.geometry && feature.properties?.ZoneName === sourceZone
				);
				if (sourceFeatureIdx < 0)
				{
					globalSession.error('Unable to find the source zone the reassignment should come from.');
					return state;
				}

				// Push the current state to the un-do stack
				const currentState = _.cloneDeep(state.zoneEditingState);
				state.undoStack.push(currentState);

				let destinationFeatureIdx = currentEditingZones.features.findIndex(
					(feature) => feature.properties?.ZoneName === destinationZone
				);
				if (destinationFeatureIdx < 0)
				{
					console.warn('Unable to find the destination zone the reassignment should assign to.');
					currentEditingZones.features.push(
						multiPolygon([], state.zoneEditingState.zones[destinationZone])
					);
					destinationFeatureIdx = currentEditingZones.features.length - 1;
				}

				// Now we just move the selected polygon from the source to the destination
				const polygonToMove = currentEditingZones.features[sourceFeatureIdx].geometry.coordinates[sourcePolygon];
				const polygonToMoveType = currentEditingZones.features[sourceFeatureIdx].geometry.type;
				const originalSourceFeature = currentEditingZones.features[sourceFeatureIdx];
				const sourcePolygons = [...originalSourceFeature.geometry.coordinates];
				// Remove the poly from the origin
				sourcePolygons.splice(sourcePolygon, 1);

				const originalDestinationFeature = currentEditingZones.features[destinationFeatureIdx];

				// If the destination is a polygon, it needs to be converted to a multipolygon
				if (originalDestinationFeature.geometry.type.toLowerCase() === 'polygon')
				{
					originalDestinationFeature.geometry.type = 'MultiPolygon';
					originalDestinationFeature.geometry.coordinates = [originalDestinationFeature.geometry.coordinates] as Position[][][];
				}

				// Append the poly to the destination - if it was actually a polygon that we're moving, make sure to array-ize the coords
				const destinationPolygons = 
					[...originalDestinationFeature.geometry.coordinates, polygonToMoveType.toLowerCase() === 'polygon' ? [polygonToMove] : polygonToMove];
								

				const features = [...currentEditingZones.features.filter((feature, featureIdx) =>
					featureIdx !== sourceFeatureIdx && featureIdx !== destinationFeatureIdx)
				];

				if (sourcePolygons.length > 0)
				{
					features.push(multiPolygon(sourcePolygons as Position[][][], originalSourceFeature.properties));
				}
				if (destinationPolygons.length > 0)
				{
					features.push(multiPolygon(destinationPolygons as Position[][][], originalDestinationFeature.properties));
				}
				const updatedFeatures = { ...currentEditingZones, features: features };

				// Recalculate the acreage
				const allZones = state.zoneEditingState.zones;
				const zonesWithFeatures: string[] = [];
				const sqMetersToAcresConversion = 0.000247;

				const zoneCollection: { [key: string]: IZoneData } = {};
				let totalArea = 0;
				updatedFeatures.features.forEach(feature =>
				{
					const zoneName = feature.properties.ZoneName;
					const featureArea = area(feature);
					const acres = featureArea > 0 ? featureArea * sqMetersToAcresConversion : 0; // convert from square meters to acres
					
					if (!zoneCollection[zoneName])
					{
						zoneCollection[zoneName] = {
							...feature.properties,
							Acres: 0
						};
					}

					const zoneAcres = acres + zoneCollection[zoneName].Acres;
					feature.properties.Acres = zoneAcres;
					zoneCollection[zoneName].Acres = zoneAcres;
					totalArea += acres;

					if (zonesWithFeatures.findIndex(zwf => zwf === zoneName.toString()) < 0)
					{
						zonesWithFeatures.push(zoneName.toString());
					}
				});

				if (allZones)
				{
					Object.keys(allZones).forEach((zoneIndex) =>
					{
						if (!zonesWithFeatures.includes((zoneIndex)) && allZones)
						{
							// if a zone no longer has a feature associated with it, it's area should be set to zero
							const existingZone = allZones[zoneIndex];
							existingZone.Acres = 0;
							zoneCollection[zoneIndex] = existingZone;
						}
					});
				}

				// Update the existing feature collection
				state.zoneEditingState.editedFeatures = updatedFeatures;
				state.zoneEditingState.zones = zoneCollection;
				state.zoneEditingState.totalArea = totalArea;
			}

			return state;
		},
		undoLastAction: (state) =>
		{
			if (state.undoStack.length > 0)
			{				
				const lastEntry = _.cloneDeep(state.undoStack[state.undoStack.length - 1]);
				state.undoStack = state.undoStack.filter((e, idx) => idx !== state.undoStack.length - 1);				
				state.zoneEditingState = lastEntry;
			}

			return state;
		},
		resetAllActions: (state) =>
		{
			if (state.undoStack.length > 0)
			{
				const firstEntry = _.cloneDeep(state.undoStack[0]);
				state.undoStack = [];
				state.zoneEditingState = firstEntry;
			}

			return state;
		},
		addZone: (state, { payload }: PayloadAction<IZoneData>) =>
		{
			// Push the current state to the un-do stack
			const currentState = _.cloneDeep(state.zoneEditingState);
			state.undoStack.push(currentState);

			state.zoneEditingState.zoneIds.push(payload.ZoneName);
			state.zoneEditingState.zones[payload.ZoneName] = payload;

			// TODO: on an actual save setup planting rates for the new zone
			// Currently copied from the previous zone

			return state;
		},
		saveNewZone: (state, { payload }: PayloadAction<{ zoneName: number, yield: number, seedingRates: {[key: string]: ISeedRateData} }>) =>
		{
			// Update the yield target on the new zone
			state.zoneEditingState.zones[payload.zoneName].TargetYield = payload.yield;
			state.zoneEditingState.zones[payload.zoneName].AddedZone = false;
			state.zoneEditingState.zones[payload.zoneName].SeedRates = payload.seedingRates;

			return state;
		},
	},
	extraReducers: (builder) =>
	{
		builder.addCase(cutPolygons.pending, (state, action) =>
		{
			state.isLoading = true;
			state.errorMessage = undefined;
			state.isError = false;
		});
		builder.addCase(cutPolygons.rejected, (state, action) =>
		{
			state.isLoading = false;
			state.isError = true;
			state.errorMessage = String(action.payload);
		});
		builder.addCase(cutPolygons.fulfilled, (state, { payload }: PayloadAction<FeatureCollection<MultiPolygon, { zone: number }>>) =>
		{
			state.isLoading = false;
			state.isError = false;
			state.errorMessage = undefined;

			// Push the current state to the un-do stack
			const currentState = _.cloneDeep(state.zoneEditingState);
			state.undoStack.push(currentState);

			const currentEditingZones: IZoneFeatureCollection = state.zoneEditingState.editedFeatures;

			const updatedFeatures: Feature<Geometry, IZoneData>[] = [];

			payload.features.forEach(newZoneFeature =>
			{
				// Find the specific feature associated with the zone
				const existingZoneData = currentEditingZones.features.find(existingFeature => existingFeature.properties.ZoneName === newZoneFeature.properties.zone);
				updatedFeatures.push(feature(newZoneFeature.geometry, existingZoneData.properties));
			});

			// Add the features into the collection
			state.zoneEditingState.editedFeatures = featureCollection(updatedFeatures);
		});
	},
});

export const {
	clearState,
	clearError,
	initializeZoneEditingState,
	setVRSMapInteractionMode,
	setSelectedZone,
	paintZone,
	undoLastAction,
	resetAllActions,
	addZone,
	saveNewZone,
} = managementZoneEditsSlice.actions;