import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter, useHistory, RouteComponentProps } from 'react-router-dom';
import * as queryString from 'query-string';
import loginBackground from './images/LoginBackground.png';
import loginEnogenBackground from './images/EnogenLogin.png';
import { ReactComponent as CoreLogo } from '../../assets/images/GH-GHX-Logos-Orange.svg';
import { ReactComponent as EnogenCoreLogo } from '../../assets/images/EluminateEnogenLogos.svg';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { Input } from '../../components/Input/Input';
import { Input as AntInput } from 'antd';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';
import { loginUser, ssoExchange, tokenExchange } from '../../logic/store/User/AuthSlice';
import { requestPasswordReset } from '../../logic/store/User/UserThunks';
import { ILoginRequest } from '../../logic/Models/Requests/LoginRequest';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { makeDispatch } from '../../logic/Utility/Utils';
import { CoveringLoader } from '../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';
import { ThemeName } from '../../logic/store/UI/UISlice';
import { useUrlTheme } from '../../logic/Utility/useUrlTheme';

interface ILoginProps extends PropsFromRedux, RouteComponentProps
{
	changeTheme: (name: ThemeName) => void;
}

const StyledPasswordInput = styled(AntInput.Password)`
	&.ant-input-affix-wrapper {
		cursor: pointer;
		background-color: rgb(18, 18, 18);
		border-color: darkgrey;
		border-bottom-color: white;
		border-right-color: white;
		border-radius: 4px;
		border-width: 2px;
		padding-left: 5px;
		line-height: 2.3em;
		font-size: 16px;
	}

	&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
		border-color: darkgrey;
		border-right-width: 2px;
		border-bottom-color: white;
		border-right-color: white;
	}

	> .ant-input {
		cursor: pointer;
		background-color: rgb(18, 18, 18);
		color: rgb(160, 160, 160);

		:hover {
			color: #E7E7E7;
		}
	}

	> span > span {
		&.ant-input-password-icon {
			color: white;
		}

		&.ant-input-password-icon.anticon:hover {
			color: white;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 600000s 0s, color 600000s 0s;
	}

	input:-webkit-autofill:hover {
		-webkit-text-fill-color: #E7E7E7;
	}
`;

const Login: React.FC<ILoginProps> = (props: ILoginProps) =>
{
	// Get any relevant parameters from the url
	const parsed = queryString.parse(location.search);
	
	// Redux state items
	const {
		changeTheme,
		IsAuthenticated,
		IsLoginError,
		LoginErrorMessage,
		UserAuthToken,
		InitialUserFoundationId,
		InitialGrowerFoundationId,
		SsoLogin,
		PerformExchange,
		PerformSsoExchange,
		PerformLogin,
		RequestPasswordReset,
	} = props;

	const theme = useTheme();

	const [hasAcceptedAgreement, setHasAcceptedAgreement] = useState<boolean>(false);
	const [username, setUserName] = useState<string>(localStorage.getItem('HasAcceptedTermsAndConditions') ?? '');
	const [password, setPassword] = useState<string>('');
	const [loginDisabled, setLoginDisabled] = useState<boolean>(true);
	const [error, setError] = useState(undefined);
	const [openPasswordRecoveryModal, setOpenPasswordRecoveryModal] = useState<boolean>(false);
	const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState<string>('');
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [isEnogenLogin, setIsEnogenLogin] = useState<boolean>(false);
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const postLoginProgress = useSelector((state: RootState) => state.ui.PostLoginProgress);
	const ssoTokenInUrl = typeof(parsed.sso) === 'string' && !!parsed.sso.length;
	const ssoRedirectInProgress = (
		// Not logged in but there is a v5 SSO token.
		ssoTokenInUrl
		|| (
			// Have authenticated and its SSO login.
			postLoginProgress.loginType === 'sso'
			// Ensure post login process is in progress.
			&& !['none', 'done', 'error'].includes(postLoginProgress.status)
		)
	);

	useEffect(() => 
	{
		const lastAccepted = localStorage.getItem('HasAcceptedTermsAndConditions');
		if(username?.length && lastAccepted?.toLowerCase() == username?.toLowerCase() && !hasAcceptedAgreement)
		{
			setHasAcceptedAgreement(true);
		}
	}, 
	[username, hasAcceptedAgreement]);

	const userAgreement =
		<a
			target='_blank'
			rel='noopener noreferrer'
			href='https://www.syngenta-us.com/legal/useragreement.html'
			style={{ color: theme.colors.secondary }}
		>User Agreement</a>;

	useEffect(() => 
	{
		// Old-style sso support for current logistics
		if(typeof(parsed.exchange) === 'string' && parsed.exchange.length)
		{
			setIsLoading(true);
			try
			{
				PerformExchange(parsed.exchange);
			}
			finally
			{
				setIsLoading(false);
			}
		}

		// Check for new v5 sso token
		else if(typeof(parsed.sso) === 'string' && parsed.sso.length)
		{
			setIsLoading(true);
			const exchangeToken = parsed.sso;

			const execute = async () => 
			{
				try
				{
					// Fire off the SSO exchange request
					const result = await PerformSsoExchange(exchangeToken);
					const payload = result.payload as any;
					if(payload.code && payload.message)
					{
						// Failure; display the error and remove the token from the url
						setError(payload.message);
						history.push('/login', 'Post-SSO Redirect');
					}
					
					// Sucess; Redirect as instructed by postlogin listener
					history.push(postLoginProgress.landingPage);
				}
				finally
				{
					// Give a bit of time before the spinner disappears so
					// any error message shows first.
					setTimeout(() => setIsLoading(false), 100);
				}
			};
			execute();
		}
	}, [parsed.exchange, parsed.sso]);

	useUrlTheme(setIsEnogenLogin, changeTheme);

	const onLogin = useCallback(() =>
	{
		if(!username?.length || !password?.length || !hasAcceptedAgreement)
		{
			return;
		}

		setPasswordVisible(false);
		setLoginDisabled(true);
		PerformLogin({ username, password });
		localStorage.setItem('HasAcceptedTermsAndConditions', username);
	}, [password, username, hasAcceptedAgreement]);

	// This handles the redirect after a successful login for all logins and
	// handles the case where someone deliberately navigates to login after auth.
	// Caveat: Initial SSO login is handled by SSO useEffect, not this one.
	useEffect(() =>
	{
		if(IsAuthenticated && postLoginProgress.landingPage && !ssoTokenInUrl) 
		{
			// Redirect to the landing page
			history.push(postLoginProgress.landingPage);
		}
	}, [postLoginProgress, IsAuthenticated, ssoTokenInUrl]);

	useEffect(() =>
	{
		if (IsLoginError && username && password)
		{
			setPassword('');
			setError(LoginErrorMessage);
			setLoginDisabled(true);
		}
	}, [IsLoginError, LoginErrorMessage]);

	const onChangeEmail = (e) =>
	{
		const email = e.target.value;
		setLoginDisabled(!hasAcceptedAgreement || !email.length || !password.length);
		setError(undefined);
		setUserName(email);
		if(email?.toLowerCase() != localStorage.getItem('HasAcceptedTermsAndConditions')?.toLocaleLowerCase())
		{
			setHasAcceptedAgreement(false);
		}
	};

	const onChangePassword = (e) =>
	{
		const pass = e.target.value;
		setLoginDisabled(!hasAcceptedAgreement || !username.length || !pass.length);
		setError(undefined);
		setPassword(pass);
	};

	const onChangeAgreement = (e) =>
	{
		const isChecked = e.target.checked;
		setHasAcceptedAgreement(isChecked);
		setError(undefined);
		setLoginDisabled(!isChecked || !username.length || !password.length);
	};

	const onChangeRecoveryEmail = (e) =>
	{
		const email = e.target.value;

		setPasswordRecoveryEmail(email);
	};

	const sendRecoveryEmail = () =>
	{
		// Copy the state value 
		const email = passwordRecoveryEmail;

		// Close the modal
		setOpenPasswordRecoveryModal(false);

		// Clear the email state value
		setPasswordRecoveryEmail('');

		// Send the request for an email
		RequestPasswordReset({ Email: email });
	};

	const cancelRecovery = () =>
	{
		// Clear the email
		setPasswordRecoveryEmail('');

		// Close the modal
		setOpenPasswordRecoveryModal(false);
	};

	// The route for sso login includes /login but we do not want to flash the login screen.
	if (ssoRedirectInProgress) 
	{
		return <></>;
	}

	return (
		<div style={{
			backgroundImage: 'url(' + (isEnogenLogin ? loginEnogenBackground : loginBackground) + ')',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			height: '100%',
			display: 'flex'
		}}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flexBasis: '100%',
					alignSelf: 'flex-end',
					justifyContent: 'space-evenly',
					height: '70vh',
					paddingLeft: '3vw',
					paddingRight: '3vw'
				}}
			>
				<div style={{ position: 'relative', top: '2%' }}>
					{
						isEnogenLogin ?
							<EnogenCoreLogo  width={'40vw'} height={'12vh'} />
							:
							<CoreLogo width={'40vw'} height={'8vh'} />
					}
				</div>
				<div style={{
					backgroundColor: theme.colors.darkGrey,
					color: 'white',
					display: 'flex',
					flexDirection: 'column',
					maxHeight: '70vh',
					maxWidth: '45vw',
					borderRadius: '15px 15px 0px 0px',
					padding: '4vw',
					opacity: 0.9
				}}
				>
					{error && <InfoPanel variant='error' style={{maxWidth: 465}}>{error}</InfoPanel>}
					<form style={{ display: 'flex', flexDirection: 'column' }} onKeyUp={(evt) => evt.key === 'Enter' ? onLogin() : null}>
						<Input
							label='Email'
							type='email'
							value={username}
							onChange={onChangeEmail}
							placeholder='Enter Email Address'
							style={{ marginBottom: 10 }}
						/>
						Password
						<StyledPasswordInput
							value={password}
							onChange={onChangePassword}
							placeholder='Enter Password'
							visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
						/>
						<div style={{ paddingTop: 15, alignSelf: 'flex-end' }}>
							{
								isEnogenLogin ?
		
									<a href="https://accounts.cropwise.com/recovery-password"><Button variant='outlined' type='button'>Forgot Password</Button></a>
									:
									<Button variant='outlined' onClick={() => setOpenPasswordRecoveryModal(true)} type='button'>Forgot Password</Button>
							}
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', paddingTop: 35, paddingLeft: 5 }}>
							<Checkbox style={{ color: 'white', fontWeight: 700, fontSize: '.85rem', paddingLeft: 5, paddingRight: 10 }}
								onChange={onChangeAgreement}
								checked={hasAcceptedAgreement}>
								{'I have read and accept the conditions stated in the '}{userAgreement}
							</Checkbox>
						</div>
					</form>
					<div style={{ paddingTop: 35, display: 'flex', alignSelf: 'center' }}>
						<Button variant='outlined' width='144px' onClick={onLogin} disabled={loginDisabled}>Login</Button>
					</div>
				</div>
			</div>
			<StyledModal
				title={'Forgot Password'}
				onCancel={cancelRecovery}
				open={openPasswordRecoveryModal}
				closable={true}
				bodyStyle={{ padding: 25, paddingTop: 0 }}
				destroyOnClose={true}
			>
				<div style={{ paddingBottom: 20 }}>
					Trouble signing in? Enter your email below and hit OK to receive instructions on how to reset your password.
					<Input
						variant='light'
						label='Email'
						type='email'
						value={passwordRecoveryEmail}
						onChange={onChangeRecoveryEmail}
						placeholder='Enter Email Address'
					/>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
					<Button
						className='sendRecoveryEmail_Button_OK'
						variant='outlined'
						disabled={!passwordRecoveryEmail}
						onClick={sendRecoveryEmail}
					>Send Recovery Email</Button>
				</div>
			</StyledModal>
			<CoveringLoader className={isLoading ? 'loading' : ''} duration={50}>
				<ScaleLoader color={theme.colors.ghxOrangeLM} loading={true} />
			</CoveringLoader>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	IsAuthenticated: state.auth.isAuthenticated,
	IsLoginError: state.auth.isLoginError,
	LoginErrorMessage: state.auth.loginErrorMessage,
	UserAuthToken: state.auth.userAuthToken,
	InitialUserFoundationId: state.ui.InitialUserFoundationId,
	InitialGrowerFoundationId: state.ui.InitialGrowerFoundationId,
	SsoLogin: state.userInfo.SsoLogin,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		PerformExchange: (token: string) => dispatch(tokenExchange(token)),
		PerformSsoExchange: (token: string) => dispatch(ssoExchange({ token })),
		PerformLogin: (request: ILoginRequest) => dispatch(loginUser(request)),
		RequestPasswordReset: makeDispatch(dispatch, requestPasswordReset),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const LoginComponent = withRouter(connector(Login));


