import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled, { useTheme } from 'styled-components';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { ReactComponent as LeftCaret } from '../Icons/LeftCaret.svg';
import { ReactComponent as RightCaret } from '../Icons/RightCaret.svg';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { FieldInfoCard, IFieldInfo } from './Cards/FieldInfoCard';
import { cornId, CropConfig } from '../../../logic/store/Seeds/CropsSlice';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { useAsyncDebounce } from '../../../logic/Utility/useAsyncDebounce';
import { FieldInventoryItem } from './FieldInventoryItem';
import { ReactComponent as StarIcon } from './Icons/Star.svg';
import { cloneDeep, sortBy } from 'lodash';
import { Button } from '../../../components/Button/Button';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { setAssignmentChanges, updateCropPlanFieldData } from '../../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import { IUpdateSeedAssignmentRequest, updateSeedAssignmentsForField } from '../../../logic/store/Grower/SeedAssignmentThunks';
import { AssignmentSource, AssignmentType, IUpdateHybrid } from '../../../logic/Models/Requests/SourceUpdateTypes';
import { ICropPlanField, ICropPlanFieldSeedAssignment, ICropPlanFieldWire } from '../../../logic/Models/Responses/FieldPlanResponse';
import { matchAssignmentToProduct } from './useUpdateFieldPlanProductDerivedData';
import { useCropPlanTrackingState } from './useCropPlanTrackingState';
import { useAmplitudePassthrough, useDynamicEvent } from '../../../logic/Utility/useAmplitude';
import { useScopedSession } from '../../../tracing/session';
import { EventCropPlanTags, EventSeedTags, EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../tracing/EventNames';

const FieldInventoryContainer = styled.div`
	min-width: 382px;
	max-width: 382px;
	background-color: ${themeGet('colors.white')};
	display: flex;
	flex-direction: column;
	box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
`;

const CrumbContainer = styled.div`
	align-items: center;
	background-color: #F3F4F6;
	display: flex;
	font-weight: ${themeGet('fontWeights.bold')};
	height: 35px;
	min-height: 35px;
	padding-left: 17px;
	padding-right: 25px;
`;

const FieldInventoryInnerContainer = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 10px;
	width: 100%;
`;

const FieldInventoryCard = styled.div`
	width: 100%;
	border: 1px solid;
	border-color: ${themeGet('colors.lightestGrey')};
	border-radius: 6px;
	padding: 10px;
	margin-top: 10px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
`;

interface IFieldInventoryProps
{
	fieldNumberInfo: string; // Information for the Header to display which position in the array the field is, ie. Field 1 out of 10
	selectedFieldId: string;
	close: () => void;
	onNext: () => void;
	onPrevious: () => void;
}

const mapStateToProps = (state: RootState) =>
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId)!;
	const SelectedCropPlan = state.fieldplan.fullCropPlanData.find(cp => cp.Id === state.fieldplan.selectedCropPlanId);

	return {
		AssignmentChanges: state.fieldplan.assignmentChanges,
		CurrentCropYear: state.crops.CropYear,
		FieldPlan: state.fieldplan.currentFieldPlan,
		IsFieldPlanLoading: state.fieldplan.isLoading,
		SeedingRates: state.plantingRate.rates,
		SelectedGrower,
		SelectedCropPlan: SelectedCropPlan,
		SelectedCropPlanId: state.fieldplan.selectedCropPlanId,
		AgronomicRatings: state.fieldplan.cropPlanAgronomicAttributes[state.fieldplan.selectedCropPlanId]
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) => 
{
	return {
		UpdateCropPlanFieldData: makeDispatch(dispatch, updateCropPlanFieldData),
		UpdateSeedAssignmentsForField: makeDispatch(dispatch, updateSeedAssignmentsForField),
		FlagAssignmentChanges: makeDispatch(dispatch, setAssignmentChanges),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FieldInventoryView = (props: IFieldInventoryProps & PropsFromRedux) =>
{
	const {
		fieldNumberInfo,
		selectedFieldId,
		close,
		onNext,
		onPrevious,
		AssignmentChanges,
		CurrentCropYear,
		SeedingRates,
		SelectedCropPlan,
		SelectedGrower,
		AgronomicRatings,
		FlagAssignmentChanges,
		UpdateCropPlanFieldData,
		UpdateSeedAssignmentsForField,
	} = props;

	const theme = useTheme();
	
	const trackingData = useCropPlanTrackingState();

	const [selectedAgronomic, setSelectedAgronomic] = useState<string>('Match Strength');
	const [showRemainingAcresError, setShowRemainingAcresError] = useState<boolean>(false);

	const currentField = SelectedGrower.Farms.flatMap(fa => fa.Fields).find(f => f.Id === selectedFieldId)!;
	const currentFarm = SelectedGrower.Farms.find(fa => fa.Fields.findIndex(f => f.Id === selectedFieldId) >= 0)!;
	const cropPlanField = SelectedCropPlan?.Fields.find(f => f.FieldId === selectedFieldId) as unknown as (ICropPlanFieldWire | undefined);
	const remainingAcres = useMemo(() => cropPlanField?.SeedAssignments.map(item => item.AppliedAcres).reduce((prev, current) =>
		RoundAcres(prev) - RoundAcres(current), RoundAcres(currentField.Area) || 0),[cropPlanField, currentField]);

	// Get top-level summary data
	const cropPlanDerivedData = useSelector((state:RootState) => state.fieldplan.selectedCropPlanDerivedData)!;

	// Calculate what agronomic attributes are available for selection.
	const agronomicOptions = useMemo(() => 
	{
		const agronomicOptions:IOptionItem[] = 
		[
			{
				label: <div>Match Strength</div>,
				value: 'Match Strength'
			},
		];

		if (AgronomicRatings?.Attributes)
		{
			AgronomicRatings.Attributes.forEach(attribute =>
			{
				agronomicOptions.push(
					{
						label: <div>{attribute.Name}</div>,
						value: attribute.Id
					}
				);
			});
		}

		return agronomicOptions;
	}, [AgronomicRatings.Attributes]);

	// Derive a compound field object that includes an expanded data set used to show the field inventory.
	const currentFieldInfo: IFieldInfo = useMemo(() => 
	{
		let expectedSeedAssignments = SelectedCropPlan?.Portfolio.map(seed => seed.SeriesId) ?? [];
		let largestAreaAssigned = -1;
		let colorAssigned = theme.colors.lightGrey;
		// Include actual seed assignments
		const seedAssignmentsWithData = cropPlanField?.SeedAssignments.map<ICropPlanFieldSeedAssignment>(sa => 
		{
			const matchingProduct = cropPlanDerivedData.products.find(p => matchAssignmentToProduct(sa, p));
			const matchingSummary = cropPlanDerivedData.summary.find(s => matchAssignmentToProduct(sa, s.product));
			expectedSeedAssignments = expectedSeedAssignments.filter(esa => esa !== matchingProduct?.SeriesId);
			// If this is the largest assigned seed, we will use its color for the field color
			if(sa.AppliedAcres > largestAreaAssigned && matchingSummary?.color)
			{
				largestAreaAssigned = sa.AppliedAcres;
				colorAssigned = matchingSummary.color;
			}

			const quantity = sa.AppliedAcres === 0 ? 0 : (sa.AppliedAcres * (sa?.Rate ?? 0)) / CropConfig()[SelectedCropPlan?.CropId ?? ''].seedsPerBag;
			return {
				AppliedAcres: sa.AppliedAcres,
				IsExternal: false,
				Treatment: matchingProduct?.TreatmentName ?? '',
				CustomTreatmentId: matchingProduct?.CustomTreatmentId,
				TreatmentPrice: 0,
				Unit: 'Seeds/ac',
				Units: quantity,
				HybridId: sa.HybridId,
				HybridName: matchingProduct?.HybridName,
				IsRecommended: cropPlanField.RecommendedSeriesId === matchingProduct?.SeriesId,
				MatchStrength: cropPlanField.MatchStrengths.find(ms => ms.SeriesId === matchingProduct?.SeriesId)?.YieldStrength,
				TraitFullName: matchingProduct?.TraitFullName,
				RelativeMaturity: matchingProduct?.RelativeMaturity,
				SeriesId: matchingProduct?.SeriesId,
				SeriesName: matchingProduct?.SeriesName,		
				Color: matchingSummary?.color ?? theme.colors.lightGrey,
				Selected: true,
				Rate: sa.Rate,
			};
		}) ?? [];

		// Now add items for the non-assigned products
		const seedAssignmentsWithoutData = expectedSeedAssignments.map<ICropPlanFieldSeedAssignment>(seriesId => 
		{
			const esa = cropPlanDerivedData.recommendedProducts[seriesId];
			const matchingSummary = cropPlanDerivedData.summary.find(s => s.product.HybridId === esa.HybridId && (s.product.CustomTreatmentId === esa.CustomTreatmentId && s.product.TreatmentName === esa.TreatmentName));
			return {
				AppliedAcres: 0,
				IsExternal: false,
				Treatment: esa.TreatmentName,
				CustomTreatmentId: esa.CustomTreatmentId,
				TreatmentPrice: 0,
				Unit: 'Seeds/ac',
				Units: 0,
				HybridId: esa.HybridId,
				HybridName: esa.HybridName,
				IsRecommended: cropPlanField?.RecommendedSeriesId === esa.SeriesId,
				MatchStrength: cropPlanField?.MatchStrengths.find(ms => ms.SeriesId === matchingSummary?.product.SeriesId)?.YieldStrength,
				TraitFullName: esa.TraitFullName,
				RelativeMaturity: esa.RelativeMaturity,
				SeriesId: esa.SeriesId,
				SeriesName: esa.SeriesName,		
				Color: matchingSummary?.color ?? theme.colors.lightGrey,
				Selected: false,
				Rate: 0,
			};
		});

		return {
			...currentField,
			FarmName: currentFarm?.Name,
			FillColor: colorAssigned,
			PredictiveYield: SelectedCropPlan?.CropId === cornId ? currentField?.CornEstimatedYield : currentField?.SoybeanEstimatedYield,
			RemainingAcres: remainingAcres ?? 0,
			SeedAssignments:[...seedAssignmentsWithData, ...seedAssignmentsWithoutData]
		};
	}, [SelectedCropPlan?.CropId, SelectedCropPlan?.Portfolio, cropPlanDerivedData.products, cropPlanDerivedData.recommendedProducts, cropPlanDerivedData.summary, cropPlanField?.MatchStrengths, cropPlanField?.RecommendedSeriesId, cropPlanField?.SeedAssignments, currentFarm?.Name, currentField, remainingAcres]);


	/**
	 * Debounced callback - Update the field seed assignments
	 */
	const innerUpdateFieldSeedAssignments = useCallback(async (selectedField: ICropPlanField) => 
	{
		if(!selectedFieldId)
		{
			return;
		}

		// The values for Type, SourceId, SourceType and SourceName cannot be changed here, or the Crop Plan will not register
		// that the assignment belongs to it.
		const request: IUpdateSeedAssignmentRequest = 
		{
			FieldId: selectedField.FieldId,
			FieldYear: CurrentCropYear,
			Hybrids: [],
			Type: AssignmentType.Recommended,
			SourceId: SelectedCropPlan.Id,
			SourceType: AssignmentSource.FieldGamePlan,
			SourceName: SelectedCropPlan.Name,
		};
		
		selectedField.SeedAssignments.forEach(inventoryItem =>
		{
			if (inventoryItem.AppliedAcres > 0)
			{
				// There are no actual external products at the moment, but this is for when they are added
				const isExternal = false; // TODO: Once field plan returns an external property, reference that.
				if(isExternal)
				{
					const hybridBrand = 'TODO'; // TODO: Once field plan returns an external property, reference that.
					const hybridName = 'TODO'; // TODO: Once field plan returns an external property, reference that.
					const hybridRm = 0; // TODO: Once field plan returns an external property, reference that.
					const hybridItem: IUpdateHybrid =
					{
						IsSyngenta: false,
						IsExternal: true,
						Rate: inventoryItem.Rate,
						AppliedAcres: inventoryItem.AppliedAcres,
						Brand: hybridBrand,
						CropId: SelectedCropPlan.CropId,
					};
					if (hybridName)
					{
						hybridItem.Name = hybridName;
					}
					if (hybridRm)
					{
						hybridItem.Rm = hybridRm;
					}

					request.Hybrids.push(hybridItem);
				}
				else
				{
					const hybridItem: IUpdateHybrid =
					{
						Id: inventoryItem.HybridId,
						IsSyngenta: true,
						IsExternal: false,
						Rate: inventoryItem.Rate,
						AppliedAcres: inventoryItem.AppliedAcres,
					};
					request.Hybrids.push(hybridItem);
				}
			}
		});

		await UpdateSeedAssignmentsForField(request);
	}, [CurrentCropYear, SelectedCropPlan.CropId, SelectedCropPlan.Id, SelectedCropPlan.Name, UpdateSeedAssignmentsForField, selectedFieldId]);

	const updateFieldSeedAssignments = useAsyncDebounce(innerUpdateFieldSeedAssignments);

	useEffect(() =>
	{
		if (AssignmentChanges)
		{
			// This is here because the functions cannot call each other, or they'll be one step behind on the SelectedCropPlan changes.
			updateFieldSeedAssignments(SelectedCropPlan.Fields.find(f => f.FieldId === selectedFieldId)!);
			FlagAssignmentChanges(false);
		}
	}, [AssignmentChanges, FlagAssignmentChanges, SelectedCropPlan.Fields, selectedFieldId, updateFieldSeedAssignments]);

	const session = useScopedSession(FieldInventoryView.name, trackingData, {
		[EventStructureTags.PageContext]: 'field_inventory',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	
	const onChangeAgronomic = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeAgronomicRating, (value: string) =>
	{
		setSelectedAgronomic(value);
	}, [setSelectedAgronomic]);


	const trackEvent = useDynamicEvent(session);

	// Support assigning a hybrid, unassigning a hybrid, or switching from one hybrid to another
	const onChangeFieldSeedSelection = useCallback((hybridId: string, checked: boolean, newHybridId?: string) =>
	{
		let changes: boolean = false;
		const updatedFields: ICropPlanField[] = cloneDeep(SelectedCropPlan?.Fields ?? []).map(f =>
		{
			if (f.FieldId === selectedFieldId)
			{
				// A utility method that will add a hybrid to a field -- may be called twice.
				const addHybridProductToField = (hybridId: string, area: number) =>
				{
					let products = Object.values(cropPlanDerivedData.recommendedProducts).filter(rp => rp.HybridId === hybridId);
					
					// Handle when this isn't a recommended product at all (it came from a trait switch)
					if(products.length === 0)
					{
						products = sortBy(cropPlanDerivedData.products.filter(p => p.HybridId === hybridId), p => p.TreatmentName?.length);
					}
					
					// Grab the (only if reccommended, top if trait-switch) product that is being assigned, and find its seeding rate
					const product = products[0];
					const seedingRatesBySeries = SeedingRates[product.SeriesName];
					const seedingRateByYield = seedingRatesBySeries ? 
						seedingRatesBySeries[currentFieldInfo.PredictiveYield.toLocaleString('en-US', {maximumFractionDigits:0})] ?
							seedingRatesBySeries[currentFieldInfo.PredictiveYield.toLocaleString('en-US', {maximumFractionDigits:0})]
							: CropConfig()[SelectedCropPlan?.CropId ?? ''].defaultSeedingRate
						: CropConfig()[SelectedCropPlan?.CropId ?? ''].defaultSeedingRate;

					// Add this hybrid!
					updatedAssignments = [...updatedAssignments, {
						HybridId: hybridId,
						Rate: seedingRateByYield,
						Treatment: product.CustomTreatmentId ?? product.TreatmentName,
						AppliedAcres: area
					}];

					changes = true;
				};

				let updatedAssignments = f.SeedAssignments;
				if(checked)
				{	
					trackEvent(EventFieldPlanNames.AddHybridToField, { [EventSeedTags.HybridId]: hybridId });
					// This is a specific recommended hybrid being added.
					addHybridProductToField(hybridId, Math.max(0, remainingAcres ?? 0));
				}
				else
				{
					trackEvent(EventFieldPlanNames.RemoveHybridFromField, { [EventSeedTags.HybridId]: hybridId });
					// This is a specific hybrid being unassigned
					updatedAssignments = updatedAssignments.filter(a => a.HybridId !== hybridId);
					changes = true;
				}

				// Recalculate remaining acres
				const newRemainingAcres = updatedAssignments.map(item => item.AppliedAcres).reduce((prev, current) =>
					RoundAcres(prev) - RoundAcres(current), RoundAcres(currentField.Area));

				if(newHybridId)
				{
					// This is a trait switch -- use the remaining acres calculated 
					// AFTER the previous hybrid has been removed.
					trackEvent(EventFieldPlanNames.ChangeHybridTraitOnField, { [EventSeedTags.HybridId]: newHybridId });
					addHybridProductToField(newHybridId, newRemainingAcres);
					changes = true;
				}

				// In the case that a new hybrid was added, validate our acreage.
				if (checked)
				{
					if (newRemainingAcres < 0)
					{
						// If we've gone over the allowed acreage a field has available, mark any changes as false so nothing is saved.
						changes = false;
						setShowRemainingAcresError(true);
					}
				}

				if(newRemainingAcres >= 0)
				{
					// Clear any existing errors
					setShowRemainingAcresError(false);
				}

				return {...f, 
					SeedAssignments: updatedAssignments
				};
			}

			return f;
		});

		UpdateCropPlanFieldData([...updatedFields]);

		if (changes)
		{
			FlagAssignmentChanges(true); // Trigger a useEffect to save the changes to the seed assignments
		}
	},[SelectedCropPlan?.Fields, SelectedCropPlan?.CropId, UpdateCropPlanFieldData, selectedFieldId, currentField.Area, cropPlanDerivedData.recommendedProducts, cropPlanDerivedData.products, SeedingRates, currentFieldInfo.PredictiveYield, trackEvent, remainingAcres, FlagAssignmentChanges]);

	const updateFieldHybridAcres = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeHybridAcresOnField, (hybridId: string, value: number) =>
	{
		if (!SelectedCropPlan)
		{
			return;
		}
		let changes: boolean = false;
		const updatedFields: ICropPlanField[] = cloneDeep(SelectedCropPlan.Fields).map(f =>
		{
			if (f.FieldId === selectedFieldId)
			{
				const updatedAssignments = cropPlanField?.SeedAssignments.map(item =>
				{
					if (item.HybridId === hybridId)
					{
						// No changes
						if (item.AppliedAcres === value)
						{
							return item;
						}
						else
						{
							changes = true;

							// Update the acre value
							return {...item, AppliedAcres: value};
						}
					}
					
					return item;
				}) ?? [];

				// Recalculate remaining acres
				const newRemainingAcres = updatedAssignments.map(item => item.AppliedAcres).reduce((prev, current) =>
					RoundAcres(prev) - RoundAcres(current), RoundAcres(currentField.Area));
				if (newRemainingAcres < 0)
				{
					// If we've gone over the allowed acreage a field has available, mark any changes as false so nothing is saved.
					changes = false;
					setShowRemainingAcresError(true);
				}
				else
				{
					// Clear any existing errors
					setShowRemainingAcresError(false);
				}

				return {...f, SeedAssignments: updatedAssignments};
			}

			return f;
		});

		UpdateCropPlanFieldData([...updatedFields]);

		// Call to the server to do an automatic save
		if (changes)
		{
			FlagAssignmentChanges(true); // Trigger a useEffect to save the changes to the seed assignments
		}
	},[SelectedCropPlan, UpdateCropPlanFieldData, selectedFieldId, cropPlanField?.SeedAssignments, currentField.Area, FlagAssignmentChanges]);

	const updateFieldHybridRate = useAmplitudePassthrough(session, EventFieldPlanNames.ChangeHybridRateOnField, (hybridId: string, value: number) =>
	{
		if (!SelectedCropPlan)
		{
			return;
		}

		let changes: boolean = false;
		const updatedFields: ICropPlanField[] = cloneDeep(SelectedCropPlan.Fields).map(f =>
		{
			if (f.FieldId === selectedFieldId)
			{
				const updatedAssignments = cropPlanField?.SeedAssignments.map(item =>
				{
					if (item.HybridId === hybridId)
					{
						// No changes
						if (item.Rate === value)
						{
							return item;
						}
						else
						{
							changes = true;
							// Update the acre value
							return {...item, Rate: value};
						}
					}
					
					return item;
				}) ?? [];
				return {...f, SeedAssignments: updatedAssignments};
			}

			return f;
		});

		UpdateCropPlanFieldData([...updatedFields]);

		// Call to the server to do an automatic save
		if (changes)
		{
			FlagAssignmentChanges(true); // Trigger a useEffect to save the changes to the seed assignments
		}
	},[SelectedCropPlan, UpdateCropPlanFieldData, selectedFieldId, cropPlanField?.SeedAssignments, FlagAssignmentChanges]);

	return (
		<FieldInventoryContainer className='MaxScript_FieldInventoryContainer'>
			<CrumbContainer className='MaxScript_FieldInventory_PreviousNextContainer'>
				<div style={{ cursor: 'pointer' }} onClick={onPrevious}>
					<LeftCaret style={{ marginRight: '15px', position: 'relative', top: 2 }} />
					<span style={{ color: theme.colors.darkGrey }}>{'Previous'}</span>
				</div>
				<div style={{ paddingLeft: '15%' }}>{fieldNumberInfo}</div>
				<div style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={onNext}>
					<span style={{ color: theme.colors.darkGrey }}>{'Next'}</span>
					<RightCaret style={{ marginLeft: '15px', position: 'relative', top: 2 }} />
				</div>
			</CrumbContainer>
			<FieldInventoryInnerContainer>
				<FieldInfoCard fieldInfo={currentFieldInfo} showRemainingAcresError={showRemainingAcresError} />
				<FieldInventoryCard style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto',  overflow: 'hidden', height: '100%' }}>
					<div style={{ marginTop: 5 }}>
						<Dropdown
							variant='outlined'
							options={agronomicOptions}
							onSelect={onChangeAgronomic}
							selected={selectedAgronomic}
							placeholder='Choose an attribute'
							className='Attribute_Dropdown'
						/>
					</div>
					<div className='Field_Inventory_Items' style={{ marginTop: 20, overflowY: 'auto' }}>
						{
							currentFieldInfo.SeedAssignments?.filter(i => !i.IsExternal).sort(dynamicSort('MatchStrength', 'descending', 'HybridName')).map(item =>
								<FieldInventoryItem
									key={`FieldInventory_${item.HybridId}`}
									cropId={SelectedCropPlan?.CropId ?? ''}
									fieldAcreage={currentField?.Area}
									fieldInventoryItem={item}
									agronomicSelection={selectedAgronomic}
									onChangeSeedSelection={onChangeFieldSeedSelection}
									updateFieldHybridAcres={updateFieldHybridAcres}
									updateFieldHybridRate={updateFieldHybridRate}
									remainingAcres={remainingAcres ?? 0}
									agronomicRates={AgronomicRatings}
								/>
							)
						}
						{
							/* There are no actual external products at the moment, but this is for when they are added */
							currentFieldInfo.SeedAssignments?.filter(i => i.IsExternal).sort(dynamicSort('HybridId')).map(item =>
								<FieldInventoryItem
									key={`FieldInventory_${item.HybridBrand}_${item.HybridName}_${item.RelativeMaturity}`}
									cropId={SelectedCropPlan?.CropId ?? ''}
									fieldAcreage={currentField?.Area}
									fieldInventoryItem={item}
									agronomicSelection={selectedAgronomic}
									onChangeSeedSelection={onChangeFieldSeedSelection}
									updateFieldHybridAcres={updateFieldHybridAcres}
									updateFieldHybridRate={updateFieldHybridRate}
									remainingAcres={remainingAcres ?? 0}
									agronomicRates={AgronomicRatings}
								/>
							)
						}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto', marginTop: 10, alignSelf: 'flex-end' }}>
						<StarIcon style={{ position: 'relative', top: -1 }} />
						<div style={{ fontSize: theme.fontSizes.tiny, fontWeight: theme.fontWeights.bold }}>= Recommended Seed</div>
					</div>
				</FieldInventoryCard>
			</FieldInventoryInnerContainer>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  marginTop: 'auto', padding: '10px 20px 10px 20px' }}>
				<Button
					disabled={false}
					className='BackToProposal_Button'
					style={{ height: 40, minWidth: 160, marginRight: 15 }}
					variant='outlined'
					onClick={close}
				>
					Close
				</Button>
			</div>
		</FieldInventoryContainer>
	);
};

export const ConnectedFieldInventoryView = connector(FieldInventoryView);
