'use strict';
import React from 'react';
import { Select, Tooltip } from 'antd';
import { SelectionState, ICrossReferencedGrower } from './DataTransferTroubleshootingTool';
import { useTheme } from 'styled-components';

/**
 * Helper component providing selection state for a user, growers, and farm.  Visualizes the data from useSelectionState.
 */
export const SelectionComponent = (props: { state: SelectionState; highlight?: Record<string, ICrossReferencedGrower> | undefined; isLoading: boolean }) => 
{
	const { state, highlight, isLoading } = props;

	const theme = useTheme();

	return <>
		<Select
			showSearch={true}
			optionFilterProp='label'
			style={{ width: '100%' }}
			value={state.user?.UserId}
			onChange={state.selectUser}>
			<Select.Option key={'none'} value=''>&nbsp;</Select.Option>
			{state.users.map(mu => <Select.Option key={mu.UserId} value={mu.UserId} label={`${mu.UserName} (${mu.FoundationId})`}>{mu.UserName} ({mu.FoundationId})</Select.Option>)}
		</Select>
		<div style={{ flexGrow: 1, overflow: 'auto', flexBasis: 0, padding: 8, border: '1px solid black' }}>
			{state.growers?.map(g => 
			{
				const validHighlight = (!highlight && g.Id === state.selectedGrower?.Id)
					|| (highlight && g.FoundationId?.length && highlight[g.FoundationId]?.valid);

				const validWarning = (highlight && g.FoundationId?.length && highlight[g.FoundationId] && highlight[g.FoundationId].valid === false) ? highlight[g.FoundationId].reason : undefined;

				return <div key={g.Id} style={{ padding: 4, marginBottom: 12 }}>
					<Tooltip title={validWarning}>
						<h4
							onClick={() => state.selectGrower(g.Id)}
							style={{
								backgroundColor: validHighlight
									? theme.colors.secondary :
									validWarning
										? theme.colors.availabilityRed
										: 'inherit'
							}}
						>{g.IsDeleted && '<ARCHIVED> '}{g.Name} ({g.FoundationId})</h4>
					</Tooltip>
					{state.farms?.[g.Id]?.map(farm => <div key={farm.Id}>{farm.Name} ({farm.Fields?.length ?? 0} fields, {Math.round(farm.Fields?.reduce((sum, cur) => sum + cur.Area, 0) ?? 0)} acres)</div>)}
					{state.farms?.[g.Id]?.length === 0 && <div>&lt;No Farms&gt;</div>}
					{(state.farms?.[g.Id]?.length === undefined && isLoading) && <div>&lt;Loading&gt;</div>}
					{(state.farms?.[g.Id]?.length === undefined && !isLoading) && <div>&lt;Error&gt;</div>}
				</div>;
			})}
		</div>
	</>;
};
