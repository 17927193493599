import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import L, { LeafletMouseEvent } from 'leaflet';
import { AppDispatch, RootState } from '../../store/Store';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import {
	baseStyle,
	defaultZoomLevel,
	hybridLayerParams,
	maxBoundsContinentalUSParams,
	satelliteLayerParams,
	selectedOpacity,
	streetsLayerParams
} from '../Shared/MapCommon';
import turf, { feature, Feature, FeatureCollection, featureCollection, Geometry } from '@turf/turf';
import { IGrowerResponse } from '../../Models/Responses/GrowerResponse';
import { IFieldResponse } from '../../Models/Responses/FieldResponse';
import { ZoomTray } from '../ZoomTray';
import { getSoilData } from '../../store/SoilData/SoilDataSlice';
import { colorToHex, makeDispatch } from '../../Utility/Utils';
import { ISoilDataResponse } from '../../Models/Responses/SoilDataResponse';
import { FieldPlanButtonTray } from './FieldPlanButtonTray';
import { IFieldSoilData } from '../PopUp/MapPopUp';
import { get } from 'lodash';
import { useAmplitudePassthrough } from '../../../logic/Utility/useAmplitude';

/**
 * In case a map marker is ever needed, this is a workaround, otherwise it will fail to get
 * the marker icon file properly.
 * Ref: https://github.com/PaulLeCam/react-leaflet/issues/453
 */
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { FieldPlanViewState } from '../../../pages/FieldActivities/FieldPlan/FieldPlan';
import _ from 'lodash';
import { matchAssignmentToProduct } from '../../../pages/FieldActivities/FieldPlan/useUpdateFieldPlanProductDerivedData';
import { useCropPlanTrackingState } from '../../../pages/FieldActivities/FieldPlan/useCropPlanTrackingState';
import { useTheme } from 'styled-components';
import { useScopedSession } from '../../../tracing/session';
import { EventStructureTags } from '../../../tracing/EventTagNames';
import { EventFieldPlanNames } from '../../../tracing/EventNames';
 
const DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});
 
L.Marker.prototype.options.icon = DefaultIcon;
/**
 * end map marker solution
 */

interface IFieldPlanMapProps extends PropsFromRedux
{
	currentViewState: FieldPlanViewState;
	selectedCropPlanId: string;
	selectedFieldId: string;
	selectedGrower: IGrowerResponse;
	selectField: (fieldId: string) => void;
}

const mapStateToProps = (state: RootState) => ({
	AllCropPlans: state.fieldplan.fullCropPlanData,
	HighlightedHybridId: state.fieldplan.highlightHybridId,
	IsLoadingSoilData: state.soilData.isLoading,
	SelectedFieldIds: state.ui.SelectedFields,
	FieldPlan: state.fieldplan.currentFieldPlan,
	MapboxToken: state.config.MapboxAccessToken,
	SelectedCropPlanId: state.fieldplan.selectedCropPlanId,
	SelectedCropPlan: (state.fieldplan.currentFieldPlan && state.fieldplan.selectedCropPlanId)
		? state.fieldplan.currentFieldPlan.CropPlans.find(cp => cp.Id === state.fieldplan.selectedCropPlanId)
		: undefined
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		DownloadSoilMap: makeDispatch(dispatch, getSoilData)
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FieldPlanMapComponent = (props: IFieldPlanMapProps) =>
{
	const {
		currentViewState,
		selectedGrower,
		selectedCropPlanId,
		selectedFieldId,
		selectField,
		AllCropPlans,
		HighlightedHybridId,
		IsLoadingSoilData,
		SelectedFieldIds,
		FieldPlan,
		MapboxToken,
		SelectedCropPlan,
		DownloadSoilMap,
	} = props;

	const theme = useTheme();

	const trackingData = useCropPlanTrackingState();
	const session = useScopedSession(FieldPlanMapComponent.name, trackingData, {
		[EventStructureTags.PageContext]: 'map',
		[EventStructureTags.PageUrl]: window.location.toString()
	});
	const trackSelectField = useAmplitudePassthrough(session, EventFieldPlanNames.SelectField, selectField, [selectField]);

	// Get top-level summary data
	const cropPlanDerivedData = useSelector((state:RootState) => state.fieldplan.selectedCropPlanDerivedData)!;
	
	const [fieldSoilData, setFieldSoilData] = useState<{ [key: string] : FeatureCollection<Geometry> }>({});
	const [showSoilMapButton, setShowSoilMapButton] = useState<boolean>(true);

	// Reference to the map control
	const mapRef = useRef<L.DrawMap | undefined>(undefined);

	// Store the element the map will be rendered into
	const [mapContainer, setMapContainer] = useState<HTMLDivElement>();

	// Store the geojson data layers we will use to represent everything.
	const [fieldLayer] = useState(L.geoJSON());

	// Soil zone layer
	const [soilDataLayer] = useState(L.geoJSON());

	// Base Layers for the map
	const hybridLayer = L.tileLayer((hybridLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), hybridLayerParams[1] as {attribution: string});
	const streetsLayer = L.tileLayer((streetsLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), streetsLayerParams[1] as {attribution: string});
	const satelliteLayer = L.tileLayer((satelliteLayerParams[0] as string).replace('MAPBOXTOKEN', MapboxToken), satelliteLayerParams[1] as {attribution: string});

	// Event to catch a resize
	window.dispatchEvent(new Event('resize'));

	// Create a layer object to pass to the layer control on the map
	const baseMaps = {
		'Hybrid': hybridLayer,
		'Streets': streetsLayer,
		'Satellite': satelliteLayer
	};

	// Continental US bounds
	const maxBoundsContinentalUS: L.LatLngBounds =  L.latLngBounds(
		L.latLng(maxBoundsContinentalUSParams[0][0], maxBoundsContinentalUSParams[0][1]), //Southwest
		L.latLng(maxBoundsContinentalUSParams[1][0], maxBoundsContinentalUSParams[1][1])  //Northeast
	);
	
	// Create the default map styler that will apply color to the rendered vectors.
	const styler = ((feature: Feature<Geometry, IFieldResponse>) =>
	{
		// Figure out how we should be styling the fields based on where we are in the UI
		let styleMethod: 'ColorByPlan' | 'ColorBySeed' | 'HighlightSelected' = 'ColorByPlan';
		if(currentViewState === FieldPlanViewState.ProductInventory)
		{
			styleMethod = 'ColorBySeed';
		}

		if(currentViewState === FieldPlanViewState.CreateCropPlan || currentViewState === FieldPlanViewState.ManageFields)
		{
			styleMethod = 'HighlightSelected';
		}

		// In manage fields mode we will set selected fields' border to a bright white
		let strokeColor: string | undefined = undefined;
		let strokeWeight: number | undefined = undefined;
		if (styleMethod === 'HighlightSelected')
		{
			if (SelectedFieldIds.includes(feature.properties?.Id))
			{
				strokeColor = theme.colors.white,
				strokeWeight = 5;
			}
		}

		if(styleMethod === 'HighlightSelected' || styleMethod === 'ColorByPlan')
		{
			// Color by plan color
			const fieldId = feature.properties?.Id;
			const owningCropPlan = FieldPlan?.CropPlans?.find(cp => cp.FieldIds.includes(fieldId));
			if(owningCropPlan?.Color)
			{
				return {
					...baseStyle(theme),
					fillColor: owningCropPlan.Color,
					color: strokeColor ?? owningCropPlan.Color,
					weight: strokeWeight ?? baseStyle(theme).weight,
					fillOpacity: 0.8
				};
			}
		}

		if(styleMethod === 'ColorBySeed')
		{
			// Color by plan color
			const fieldId = feature.properties?.Id;
			const owningCropPlan = AllCropPlans?.find(cp => cp.Fields?.map(f => f.FieldId).includes(fieldId));
			const cropPlanField = owningCropPlan?.Fields?.find(f => f.FieldId === fieldId);
			const highestHybridOnField = _.maxBy(cropPlanField?.SeedAssignments, f => f.AppliedAcres);
			const highlightFieldFromHybrid = highestHybridOnField?.HybridId === HighlightedHybridId;
			const highestSeed = cropPlanField?.SeedAssignments.reduce((prev, cur) => 
			{
				if(!prev || cur.AppliedAcres > prev.AppliedAcres)
				{
					return cur;
				}
				return prev;
			}, undefined);
			
			if(highestSeed)
			{
				const item = cropPlanDerivedData.summary.find(s => matchAssignmentToProduct(highestSeed, s.product));
				return {
					...baseStyle(theme),
					fillColor: item?.color,
					color: highlightFieldFromHybrid ? theme.colors.white : item?.color,
					weight: strokeWeight ?? baseStyle(theme).weight,
					fillOpacity: highlightFieldFromHybrid ? 1 : 0.8,
				};
			}
			else
			{
				// Color fields for the Crop Plan if no seeds are applied to a field
				if(owningCropPlan?.Color)
				{
					return {
						...baseStyle(theme),
						fillColor: owningCropPlan.Color,
						color: strokeColor ?? owningCropPlan.Color,
						weight: strokeWeight ?? baseStyle(theme).weight,
						fillOpacity: 0.8
					};
				}
			}
		}

		// Any case that didn't already return an explicit style
		return {
			...baseStyle(theme),
			fillColor: theme.colors.mediumGrey,
			fillOpacity: 0.8,
			color: strokeColor ?? theme.colors.lightGrey,
			weight: strokeWeight ?? baseStyle(theme).weight,
		};
	}) as L.StyleFunction<any>;

	// Create the styler for the SoilData Layer
	const soilDataStyler = ((feature: Feature<Geometry>) =>
	{
		if (feature.properties)
		{
			const color = feature.properties.color;
			const hexColor = colorToHex(color);
			return {
				...baseStyle(theme),
				fillColor: hexColor,
				fillOpacity: selectedOpacity,
			};
		}

		return {
			...baseStyle(theme),
			color: theme.colors.blueLM,
			fillColor: theme.colors.transparentWhite
		};
	}) as L.StyleFunction<any>;

	fieldLayer.setStyle(styler);

	// When the container is rendered, save the element so we can install the map there
	const installFieldPlanMap = useCallback((ref: HTMLDivElement) =>
	{
		if (ref)
		{
			// On refreshes, if the mapRef exists, it can throw an error about the map already having been initialized
			// Removing it before we re-initialize it will fix that
			if (mapRef.current)
			{
				mapRef.current.remove();
			}

			if (!selectedGrower || !selectedGrower.ReferencePoint)
			{
				mapRef.current = L.map(ref, {
					zoomControl: false,
					layers: [hybridLayer], // default to the Streets layer
				}).fitBounds(maxBoundsContinentalUS);
			}
			else
			{
				const growerReferencePoint = selectedGrower.ReferencePoint.coordinates as turf.Position;
				mapRef.current = L.map(ref, {
					zoomControl: false,
					zoom: defaultZoomLevel,
					center: [growerReferencePoint[1], growerReferencePoint[0]],
					layers: [hybridLayer] // default to the Streets layer
				});
			}

			// Layer control - defaults to topright
			L.control.layers(baseMaps).addTo(mapRef.current);

			// Zoom control
			L.control.zoom({ position: 'bottomright' }).addTo(mapRef.current);

			setMapContainer(ref);
		}
	}, [setMapContainer]);

	useEffect(() =>
	{
		if (!mapContainer)
		{
			return;
		}

		fieldLayer.options = { pmIgnore: true };
		fieldLayer.addTo(mapRef.current);
		fieldLayer.setStyle(styler);

		soilDataLayer.options = { pmIgnore: true };
		soilDataLayer.addTo(mapRef.current);
		soilDataLayer.setStyle(soilDataStyler);

	}, [mapContainer, mapRef.current]);

	useEffect(() =>
	{
		// When the selectedFieldId changes, make sure to flip the soil button back
		if (!showSoilMapButton)
		{
			setShowSoilMapButton(true);
		}
	}, [selectedFieldId]);

	// Draws fields on the map
	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		if (!showSoilMapButton)
		{
			return;
		}

		const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);

		if (allFields?.length > 0)
		{
			// Clear the layers just in case
			fieldLayer.clearLayers();
			soilDataLayer.clearLayers();

			// Get all of the boundaries
			const fieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];

			for (let i = 0, len = allFields.length; i < len; i++)
			{
				const field = allFields[i];
				if (!allFields[i])
				{
					return;
				}

				// If we're viewing Crop Plan Products, only include fields that belong to the CropPlan
				if (currentViewState === FieldPlanViewState.ProductInventory)
				{
					if (!SelectedCropPlan?.FieldIds?.includes(field.Id))
					{
						continue;
					}
				}
				
				if (field.Boundary)
				{
					fieldBoundaries.push(feature(field.Boundary, field));
				}
			}
			if (fieldBoundaries.length > 0)
			{
				const featureLayer = fieldLayer.addData(featureCollection(fieldBoundaries)).bindTooltip(function (layer: L.Layer)
				{
					const name = get(layer, 'feature.properties.Name', '');
					const acres = Number(get(layer, 'feature.properties.Area', '')).toFixed(1);
					return name + '<br>' + 'Acres: ' + acres;
				}) as L.GeoJSON<any>;

				// Hook into the user clicking on this feature and potentially bubble up the interaction
				featureLayer.on('click', (l: LeafletMouseEvent) =>
				{
					const clickedFeature = l.propagatedFrom?.feature as Feature<Geometry, IFieldResponse>;
					const fieldId = clickedFeature.properties.Id;
					trackSelectField(fieldId);
				});
				
				fieldLayer.setStyle(styler);

				// Center based on what set of fields the user is viewing
				if (selectedFieldId && currentViewState === FieldPlanViewState.ProductInventory)
				{
					onCenterSelected();
				}
				else if (selectedCropPlanId && currentViewState === FieldPlanViewState.ManageFields)
				{
					onCenterAll();
				}
				else if (selectedCropPlanId && currentViewState === FieldPlanViewState.CreateCropPlan)
				{
					onCenterSelected();
				}
				else if (selectedCropPlanId && currentViewState === FieldPlanViewState.ProductInventory)
				{
					onCenterSelected();
				}
				else
				{
					onCenterAll();
				}

				return () =>
				{
					featureLayer.off('click');
				};
			}
		}
	}, [selectedGrower, selectedFieldId, mapRef.current, showSoilMapButton, selectedCropPlanId, currentViewState, SelectedCropPlan, trackSelectField]);

	/**
	 * Zooms to all fields in the Plan
	 */
	const onCenterAll = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// Padding will scoot the map over a bit to give space for the overlay on the left
		if (fieldLayer.getLayers().length > 0)
		{
			mapRef.current.fitBounds(fieldLayer.getBounds(), { paddingTopLeft: [10, 0] });
		}
		
	}, [mapRef.current, fieldLayer, currentViewState]);

	/**
	 * Zooms to the fields belonging to a CropPlan on the Plan or to a specific selected field
	 */
	const onCenterSelected = useCallback(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		if (selectedFieldId)
		{
			const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);

			// Get the boundary of the selected field
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			const field = allFields.find(f => f.Id === selectedFieldId);
			if (field && field.Boundary)
			{
				selectedFieldBoundaries.push(feature(field.Boundary, field));
			}
			if (selectedFieldBoundaries.length > 0)
			{
				const collection = featureCollection(selectedFieldBoundaries);
				const group = L.geoJSON(collection);
				// Padding will scoot the map over a bit to give space for the overlay on the left
				mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [10, 0] });

				fieldLayer.setStyle(styler);
			}
		}
		else if (selectedCropPlanId && SelectedCropPlan && SelectedCropPlan.FieldIds && SelectedCropPlan.FieldIds.length > 0)
		{
			const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);

			// Get all of the boundaries
			// There are some cases where the Fields have no Boundary, this may only be during
			// early development
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			for (let i = 0, len = SelectedCropPlan.FieldIds.length; i < len; i++)
			{
				const field = allFields.find(f => f.Id === SelectedCropPlan.FieldIds[i]);
				if (field && field.Boundary)
				{
					if (HighlightedHybridId)
					{
						// Only zoom to the highlighted seed fields
						const owningCropPlan = AllCropPlans?.find(cp => cp.Fields?.map(f => f.FieldId).includes(field.Id));
						const cropPlanField = owningCropPlan?.Fields?.find(f => f.FieldId === field.Id);
						const highestHybridOnField = _.maxBy(cropPlanField?.SeedAssignments, f => f.AppliedAcres);
						const highlightFieldFromHybrid = highestHybridOnField?.HybridId === HighlightedHybridId;
						if (highlightFieldFromHybrid)
						{
							selectedFieldBoundaries.push(feature(field.Boundary, field));
						}
					}
					else
					{
						selectedFieldBoundaries.push(feature(field.Boundary, field));
					}
				}
			}
			if (selectedFieldBoundaries.length > 0)
			{
				const collection = featureCollection(selectedFieldBoundaries);
				const group = L.geoJSON(collection);
				// Padding will scoot the map over a bit to give space for the overlay on the left
				mapRef.current.fitBounds(group.getBounds(), { paddingTopLeft: [10, 0] });

				fieldLayer.setStyle(styler);
			}
		}

	}, [SelectedFieldIds, mapRef.current, selectedFieldId, selectedCropPlanId, SelectedCropPlan, HighlightedHybridId, AllCropPlans, currentViewState]);

	// Load the soil data onto the currently viewed field
	const onLoadSoilMap = async () =>
	{
		// If we have not already downloaded this field
		if (!fieldSoilData[selectedFieldId])
		{
			const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);
			const field = allFields.find(f => f.Id === selectedFieldId);
			const selectedFieldBoundaries: Feature<Geometry, IFieldResponse>[] = [];
			selectedFieldBoundaries.push(feature(field.Boundary, field));
			const collection: FeatureCollection<Geometry, IFieldResponse> = featureCollection(selectedFieldBoundaries);
			const soilDataResponse = await DownloadSoilMap(collection);
			if (soilDataResponse)
			{
				if (fieldSoilData[field.Id])
				{
					fieldSoilData[field.Id] = (soilDataResponse.payload as ISoilDataResponse).data;
					setFieldSoilData({ ...fieldSoilData });
				}
				else
				{
					setFieldSoilData({ ...fieldSoilData, [field.Id]: (soilDataResponse.payload as ISoilDataResponse).data });
				}
			}
		}
		else
		{
			setFieldSoilData({ ...fieldSoilData });
		}

		setShowSoilMapButton(false);
	};

	// Display Soil Data on the soil data layer
	useEffect(() =>
	{
		if (!mapRef.current)
		{
			return;
		}

		// Don't show soil zones unless the button has been toggled
		if (showSoilMapButton)
		{
			return;
		}

		soilDataLayer.clearLayers();

		if (!IsLoadingSoilData && fieldSoilData[selectedFieldId])
		{
			// Load the field's soil data boundaries
			const soilDataBoundaries: FeatureCollection<Geometry> = fieldSoilData[selectedFieldId];
			if (soilDataBoundaries)
			{
				if (soilDataLayer.getLayers().length === 0)
				{
					soilDataLayer.addData(soilDataBoundaries) as L.GeoJSON<any>;
				}
			}

			soilDataLayer.setStyle(soilDataStyler);

			return () =>
			{
				soilDataLayer.off('click');
			};
		}
	}, [mapRef.current, fieldSoilData, IsLoadingSoilData, selectedFieldId, showSoilMapButton]);

	const getFieldSoilData = (): IFieldSoilData[] =>
	{
		const allFields = selectedGrower.Farms.flatMap(fa => fa.Fields);
		const field = allFields.find(f => f.Id === selectedFieldId);
		const allFieldSoilData: IFieldSoilData[] = [];
		if (!showSoilMapButton && selectedFieldId && fieldSoilData[selectedFieldId])
		{
			const soilData = [ ...fieldSoilData[selectedFieldId].features ];
			soilData.forEach(sd =>
			{
				const area = Number.parseFloat(sd.properties.area);
				const percentOfTotal = ((area / field.Area ) * 100).toFixed(1);
				const singleSoilSectionData: IFieldSoilData =
				{
					Acres: sd.properties.area ? sd.properties.area.toFixed(1) : '0',
					Color: colorToHex(sd.properties.color),
					MapUnit: sd.properties.mukey,
					OverallNccpi: sd.properties.nccpi ? sd.properties.nccpi.Overall.toFixed(2) : 'none',
					PercentOfTotal: percentOfTotal,
					SoilType: sd.properties.soil_type,
				};

				allFieldSoilData.push(singleSoilSectionData);
			});
		}

		return allFieldSoilData;
	};

	return (
		<div id='fieldPlanMapId' ref={installFieldPlanMap} style={{ width: 'calc(100% - 70px)', height: '100%' }}>
			<FieldPlanButtonTray
				mapRef={mapRef}
				displayTray={!!selectedFieldId}
				fieldSoilData={getFieldSoilData()}
				showSoilMapButton={showSoilMapButton}
				showFieldBoundary={() => setShowSoilMapButton(true)}
				showSoilZone={onLoadSoilMap}
			/>
			<ZoomTray
				mapRef={mapRef}
				onCenterAll={onCenterAll}
				onCenterSelected={onCenterSelected}
				displayTools={selectedGrower.Id ? true : false}
			/>
		</div>
	);
};

export const FieldPlanMap = connector(FieldPlanMapComponent);