import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { LoggedInRoute } from './LoggedInRoute';
import { PermissibleRoute } from './PermissibleRoute';
import { Footer } from '../Footer';
import { Header } from '../Header/Header';
import { LoginComponent } from '../Login/Login';
import { FieldActivities } from '../FieldActivities/FieldActivities';
import { DashboardRouter } from '../Dashboard/DashboardRouter';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, RootState } from '../../logic/store/Store';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ProgressSpinner } from '../../components/LoadingSpinner/ProgressSpinner';
import { makeDispatch } from '../../logic/Utility/Utils';
import { getApplicationConfig } from '../../logic/store/User/ConfigSlice';
import { Register } from '../Registration/Register';
import { UserAdminPage } from '../UserAdmin/UserAdminPage';
import { clearError as clearAuthError, hasEffectivePermission, clearSuccess as clearAuthSuccess, authSlice } from '../../logic/store/User/AuthSlice';
import { clearError as clearGrowerError, clearSuccess as clearGrowerSuccess } from '../../logic/store/Grower/GrowerSlice';
import { clearError as clearSeedsError } from '../../logic/store/Seeds/SeedsSlice';
import { clearError as clearCropsError } from '../../logic/store/Seeds/CropsSlice';
import { clearError as clearSeedAttrError } from '../../logic/store/Seeds/AttributesSlice';
import { clearError as clearUserAttrError } from '../../logic/store/User/UserSeedAttributesSlice';
import { clearError as clearProductGameplanError } from '../../logic/store/Plans/ProductGamePlanSlice';
import { clearError as clearCLUError } from '../../logic/store/CLUs/CLUSlice';
import { clearError as clearUserInfoError } from '../../logic/store/User/UserInfoSlice';
import { clearError as clearSoilDataError } from '../../logic/store/SoilData/SoilDataSlice';
import { clearError as clearUiError, ThemeName, setCurrentTheme, clearPostLoginProgressError, resetPostLoginProgress } from '../../logic/store/UI/UISlice';
import { clearError as clearManagmentZoneEditError } from '../../logic/store/ManagementZones/ManagementZoneSlice';
import { clearError as clearFileError } from '../../logic/store/MachineFileData/FileSlice';
import { clearError as clearBulkImportError } from '../../logic/store/BulkImport/BulkImportSlice';
import { clearError as clearFieldPlanError } from '../../logic/store/Plans/FieldPlan/FieldPlanSlice';
import styled from 'styled-components';
import { StyledModal } from '../../components/StyledModal/StyledModal';
import { Button } from '../../components/Button/Button';
import { IInteractionMode, MapInteractionMode, setMapInteractionMode, toggleDrawingTools } from '../../logic/store/UI/UISlice';
import LogRocket from 'logrocket';
import { TroubleShootingTools } from '../TroubleShootingTools/TroubleShootingTools';
import { UserPermission } from '../../logic/Models/Responses/UserPermissions';
import { clearSaveTreatmentError } from '../../logic/store/User/CustomTreatmentsSlice';
import { message } from 'antd';
import { clearSuccessNotification as clearUserAdminSuccess } from '../../logic/store/UserAdmin/UserAdminSlice';

const OverlayContainer = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 9999;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
`;

const MainParentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
`;

const MainInnerContainer = styled.div`
	height: 100%;
	overflow: hidden;
`;

interface IErrorModalProps
{
	errorMessage: string;
	clearError: () => void;
}

interface ISuccessModalProps
{
	successMessage: string;
	clearSuccess: () => void;
}

interface IRouteProps
{
	changeTheme: (name: ThemeName) => void;
}

export const RoutingComponent = (props: IRouteProps & PropsFromRedux) =>
{
	const { IsLoadingAuth, IsLoadingCreateProductPlan, IsLoadingGrower, IsDownloadingProductPlan, IsLoadingProductGamePlan,
		IsGrowerError, IsSeedsError, IsCropsError, IsSeedAttrsError, IsUserAttrsError, IsProductGameplanError, ProductGameplanErrorMessage,
		GrowerErrorMessage, SeedsErrorMessage, CropsErrorMessage, SeedAttrsErrorMessage, UserAttrsErrorMessage, IsAuthError, AuthErrorMessage,
		IsCluError, CluErrorMessage, IsLoadingCLU, IsGrowerSuccess, GrowerSuccessMessage, IsSavingProductPlan, IsLoadingUserInfo, IsUserInfoError,
		UserInfoErrorMessage, IsLoadingSoilData, IsSoilDataError, SoilDataErrorMessage, IsRecording, RecordingId, IsLoadingUi, IsLoadingFieldlessRecommendations,
		UiErrorMessage, IsUiError, CanSeeTroubleShootingTools, IsLoadingManagmentZoneEdits, IsManagementZoneEditsError, ManagementZoneEditsErrorMessage,
		IsLoadingSaveTreatments, IsSaveTreatmentsError, SaveTreatmentsErrorMessage, ShowSuccessNotification, RedirectGHXUser, IsLoadingFileList,
		FileErrorMessage, IsFileError, IsAuthSuccess, AuthSuccessMessage, IsLoadingPublish, IsBulkImportError, BulkImportErrorMessage,
		FieldPlanErrorMessage, IsLoadingFieldPlan, IsFieldPlanError, PostLoginProgress,
		UserSeesEnogenTheme, ManageableUsersError, CanSeeUserAdmin, UserAdminShowSuccessNotification,
		IsLoadingEnogenContractCheck,
		changeTheme, SetCurrentTheme,
		ClearGrowerErrors, ClearSeedErrors, ClearCropErrors, ClearSeedAttrErrors, ClearUserAttrErrors, GetApplicationConfig, ClearUserInfoErrors,
		ClearProductGameplanErrors, ClearCluErrors, ChangeMapMode, SetDisplayDrawingTools, ClearGrowerSuccess, ClearAuthErrors, ClearSoilDataErrors,
		ClearUiErrors, ClearManagmentZoneEditErrors, ClearSaveTreatmentErrors, ClearFileErrors, ClearAuthSuccess, ClearBulkImportErrors, ClearFieldPlanErrors,
		ClearPostLoginProgressErrors, ClearManageableUsersError, ClearUserAdminSuccessNotification,
	} = props;

	const isLoadingPostLogin = ['none', 'done', 'error'].every(s => PostLoginProgress.status !== s);

	const isLoading = IsLoadingAuth || IsLoadingGrower || IsLoadingProductGamePlan || IsLoadingCreateProductPlan
		|| IsLoadingCLU || IsSavingProductPlan || IsDownloadingProductPlan || IsLoadingUserInfo || IsLoadingSoilData || IsLoadingUi || IsLoadingManagmentZoneEdits
		|| IsLoadingFieldlessRecommendations || IsLoadingSaveTreatments || IsLoadingFileList || (IsLoadingFieldPlan > 0) || isLoadingPostLogin || IsLoadingEnogenContractCheck;

	const isLoadingProgress = IsLoadingPublish;
	
	const history = createBrowserHistory();

	// At application start, fetch our configuration
	useEffect(() =>
	{
		GetApplicationConfig();

		// Resume recording if needed
		if (IsRecording && RecordingId)
		{
			LogRocket.init('zqn1nh/e-luminate', {
				release: RecordingId
			});
		}
	}, []);

	useEffect(() =>
	{
		if (UserSeesEnogenTheme)
		{
			changeTheme(ThemeName.Enogen);
		}
	},[UserSeesEnogenTheme]);

	// If for any reason the user navigates away during drawing, flip the map mode back to selection
	// and hide the drawing tools
	useEffect(() =>
	{
		// Location object is from the route props
		if (location && location.pathname.indexOf('/fieldactivities') === -1)
		{
			ChangeMapMode({ mode: MapInteractionMode.Selection });
			SetDisplayDrawingTools(false);
		}
	}, [location]);

	// Show special success message notification (not the normal modal) --aka Toast
	useEffect(() =>
	{
		if (ShowSuccessNotification)
		{
			message.success({
				content: 'Changes saved.',
				className: 'Success_Notification_Message',
				duration: 2
			});
			ClearGrowerSuccess();
			ClearAuthSuccess();
		}
	}, [ShowSuccessNotification]);

	// Show special success message notification (not the normal modal) --aka Toast
	useEffect(() =>
	{
		if (UserAdminShowSuccessNotification)
		{
			message.success({
				content: 'Import succeeded.',
				className: 'UserAdmin_Success_Notification_Message',
				duration: 2
			});
			ClearUserAdminSuccessNotification();
		}
	}, [UserAdminShowSuccessNotification]);

	// Determine error data to show
	const getErrorData = (): IErrorModalProps =>
	{
		if (IsAuthError) return {
			errorMessage: AuthErrorMessage,
			clearError: ClearAuthErrors
		};

		if (ManageableUsersError) return {
			errorMessage: ManageableUsersError,
			clearError: ClearManageableUsersError
		};

		if (IsGrowerError) return {
			errorMessage: GrowerErrorMessage,
			clearError: ClearGrowerErrors
		};

		if (IsSeedsError) return {
			errorMessage: SeedsErrorMessage,
			clearError: ClearSeedErrors
		};

		if (IsCropsError) return {
			errorMessage: CropsErrorMessage,
			clearError: ClearCropErrors
		};

		if (IsSeedAttrsError) return {
			errorMessage: SeedAttrsErrorMessage,
			clearError: ClearSeedAttrErrors
		};

		if (IsUserAttrsError) return {
			errorMessage: UserAttrsErrorMessage,
			clearError: ClearUserAttrErrors
		};

		if (IsProductGameplanError) return {
			errorMessage: ProductGameplanErrorMessage,
			clearError: ClearProductGameplanErrors
		};

		if (IsCluError) return {
			errorMessage: CluErrorMessage,
			clearError: ClearCluErrors
		};

		if (IsUserInfoError) return {
			errorMessage: UserInfoErrorMessage,
			clearError: ClearUserInfoErrors
		};

		if (IsSoilDataError) return {
			errorMessage: SoilDataErrorMessage,
			clearError: ClearSoilDataErrors
		};

		if (IsUiError) return {
			errorMessage: UiErrorMessage,
			clearError: ClearUiErrors
		};

		if (IsManagementZoneEditsError) return {
			errorMessage: ManagementZoneEditsErrorMessage,
			clearError: ClearManagmentZoneEditErrors
		};

		if (IsSaveTreatmentsError) return {
			errorMessage: SaveTreatmentsErrorMessage,
			clearError: ClearSaveTreatmentErrors
		};

		if (IsFileError) return {
			errorMessage: FileErrorMessage,
			clearError: ClearFileErrors
		};

		if (IsBulkImportError) return {
			errorMessage: BulkImportErrorMessage,
			clearError: ClearBulkImportErrors
		};

		if (IsFieldPlanError) return {
			errorMessage: FieldPlanErrorMessage,
			clearError: ClearFieldPlanErrors
		};

		if (PostLoginProgress.errors.length > 0) return {
			errorMessage: 
				PostLoginProgress.errors.map(e => ({
					'initial_user_not_found': 'Selected seller is not available. Contact support to add the seller to your account.',
					'initial_grower_not_found': 'Selected grower is not available. Contact support to add the grower to your account.',
				})[e]).join('\n'),
			clearError: ClearPostLoginProgressErrors
		};

		return {
			errorMessage: undefined,
			clearError: null
		};
	};
	const errorData = getErrorData();

	const getSuccessData = (): ISuccessModalProps =>
	{
		if (IsGrowerSuccess) return {
			successMessage: GrowerSuccessMessage,
			clearSuccess: ClearGrowerSuccess
		};
		if (IsAuthSuccess) return {
			successMessage: AuthSuccessMessage,
			clearSuccess: ClearAuthSuccess
		};
	};
	const successData = getSuccessData();

	const onClickOK = () =>
	{
		const redirectValue = RedirectGHXUser;
		successData?.successMessage ? successData.clearSuccess() : errorData.clearError();

		if (IsGrowerSuccess)
		{
			// If this is a GHX user sending an order, the history needs to be cleared of the shopping cart
			if (redirectValue)
			{
				history.replace(redirectValue);
			}
		}
	};

	const updateTheme = (name: ThemeName) =>
	{
		// Update in the store and then tell the App.tsx to switch the theme file
		SetCurrentTheme(name);
		changeTheme(name);
	};

	return (
		<>
			<OverlayContainer style={{ display: isLoading ? 'flex' : 'none' }}>
				<LoadingSpinner loading={ isLoading }>Loading...</LoadingSpinner>
			</OverlayContainer>
			<OverlayContainer style={{ display: isLoadingProgress !== undefined ? 'flex' : 'none' }}>
				<ProgressSpinner percent={isLoadingProgress}>Loading...</ProgressSpinner>
			</OverlayContainer>
			<Router>
				<StyledModal
					title={ successData?.successMessage ? 'Success' : errorData.errorMessage ? '' : '' } // 8/3/2022 - ELM - Removed Error Message title
					className={successData?.successMessage ? 'success-modal' : 'error-modal'}
					open={ ShowSuccessNotification ? false : errorData.errorMessage !== undefined || successData?.successMessage !== undefined }
					width={ 600 }
					closable={ false }
				>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
						{ successData?.successMessage ? successData.successMessage : errorData.errorMessage ?? '' }
						<Link to={RedirectGHXUser ? RedirectGHXUser : '#'}>
							<Button
								variant='dashed'
								className={successData?.successMessage ? 'success-OK' : 'error-OK'}
								style={{ width: 120, marginTop: 25 }}
								onClick={() => onClickOK()}
							>
								Ok
							</Button>
						</Link>
					</div>
				</StyledModal>
				<MainParentContainer>
					<Switch> {/* Hide the header for the login and registration screens */}
						<Route exact path='/login' />
						<Route path='/register/' />
						<Route path='*'>
							<Header />
						</Route>
					</Switch>
					<MainInnerContainer>
						<Switch>
							<Route exact path='/login'>
								<LoginComponent changeTheme={updateTheme} />
							</Route>
							<Route path='/register/:invitationCode'>
								<Register changeTheme={updateTheme} />
							</Route>
							<LoggedInRoute path={['/useradmin', '/useradmin/*']}>
								<PermissibleRoute exact path={['/useradmin', '/useradmin/*']} permissiable={CanSeeUserAdmin}>
									<UserAdminPage />
								</PermissibleRoute>
							</LoggedInRoute>
							<LoggedInRoute path={['/fieldactivities', '/fieldactivities/*']}>
								<FieldActivities />
							</LoggedInRoute>
							<LoggedInRoute path={['/dashboard', '/dashboard/*']}>
								<DashboardRouter changeTheme={updateTheme} />
							</LoggedInRoute>
							<LoggedInRoute exact path='/troubleshootingtools'>
								<PermissibleRoute exact path='/troubleshootingtools' permissiable={CanSeeTroubleShootingTools}>
									<TroubleShootingTools />
								</PermissibleRoute>
							</LoggedInRoute>
							<LoggedInRoute path='*'>
								<Redirect to={{ pathname: '/dashboard' }} />
							</LoggedInRoute>
						</Switch>
					</MainInnerContainer>
					<Switch> {/* Hide the footer for the fieldactivities screen */}
						<LoggedInRoute path={['/fieldactivities', '/fieldactivities/*',]} />
						<Route path='*'>
							<Footer />
						</Route>
					</Switch>
				</MainParentContainer>
			</Router>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	IsLoadingAuth: state.auth.isLoading,
	IsLoadingGrower: state.grower.isLoading,
	IsLoadingProductGamePlan: state.productGamePlan.isLoadingGetProductPlan,
	IsLoadingFieldlessRecommendations: state.productGamePlan.isFieldlessLoading,
	IsLoadingCreateProductPlan: state.productGamePlan.isLoadingCreateProductPlan,
	IsLoadingCLU: state.clu.isLoading,
	IsLoadingUserInfo: state.userInfo.isLoading,
	IsLoadingSoilData: state.soilData.isLoading,
	IsLoadingUi: state.ui.isLoading,
	IsLoadingManagmentZoneEdits: state.managementZoneEdits.isLoading,
	IsLoadingSaveTreatments: state.customTreatments.isLoadingSaveTreatments,	
	IsLoadingFileList: state.file.isLoadingFileListing,
	IsLoadingPublish: state.bulkImport.publishingProgress,
	IsLoadingFieldPlan: state.fieldplan.isLoading,
	IsLoadingEnogenContractCheck: state.seeds.isLoadingContractCheck,
	PostLoginProgress: state.ui.PostLoginProgress,
	ManageableUsersError: state.auth.manageableUsersError,
	
	IsSavingProductPlan: state.productGamePlan.isSavingProductPlan,
	
	IsDownloadingProductPlan: state.productGamePlan.isDownloadingProductPlan,

	IsGrowerError: state.grower.isError,
	GrowerErrorMessage: state.grower.errorMessage,

	IsAuthError: state.auth.isError,
	AuthErrorMessage: state.auth.errorMessage,
	IsAuthSuccess: state.auth.isSuccess,
	AuthSuccessMessage: state.auth.successMessage,

	IsSeedAttrsError: state.seedAttributes.isError,
	SeedAttrsErrorMessage: state.seedAttributes.errorMessage,

	IsCropsError: state.crops.isError,
	CropsErrorMessage: state.crops.errorMessage,

	IsUserAttrsError: state.userAttributes.isError,
	UserAttrsErrorMessage: state.userAttributes.errorMessage,

	IsSeedsError: state.seeds.isError,
	SeedsErrorMessage: state.seeds.errorMessage,

	IsProductGameplanError: state.productGamePlan.isError,
	ProductGameplanErrorMessage: state.productGamePlan.errorMessage,

	IsCluError: state.clu.isError,
	CluErrorMessage: state.clu.errorMessage,

	IsGrowerSuccess: state.grower.isSuccess,
	GrowerSuccessMessage: state.grower.successMessage,
	RedirectGHXUser: state.grower.redirectGHXUser,
	ShowSuccessNotification: state.grower.showSuccessNotification,

	IsUserInfoError: state.userInfo.isError,
	UserInfoErrorMessage: state.userInfo.errorMessage,

	IsSoilDataError: state.soilData.isError,
	SoilDataErrorMessage: state.soilData.errorMessage,

	IsRecording: state.recording.isRecording,
	RecordingId: state.recording.cardId,

	IsUiError: state.ui.isError,
	UiErrorMessage: state.ui.errorMessage,

	IsManagementZoneEditsError: state.managementZoneEdits.isError,
	ManagementZoneEditsErrorMessage: state.managementZoneEdits.errorMessage,

	IsSaveTreatmentsError: state.customTreatments.isErrorSaveTreatments,
	SaveTreatmentsErrorMessage: state.customTreatments.errorMessage,

	IsFileError: state.file.isError,
	FileErrorMessage: state.file.errorMessage,

	IsBulkImportError: state.bulkImport.isError,
	BulkImportErrorMessage: state.bulkImport.errorMessage,

	IsFieldPlanError: state.fieldplan.isError,
	FieldPlanErrorMessage: state.fieldplan.errorMessage,

	CanSeeTroubleShootingTools: hasEffectivePermission(state, UserPermission.CanSeeTroubleShootingTools, true),
	CanSeeUserAdmin: hasEffectivePermission(state, UserPermission.UserAdministrator, true),

	UserSeesEnogenTheme: hasEffectivePermission(state, UserPermission.CanSeeEnogenTheme),

	UserAdminShowSuccessNotification: state.userAdmin.showSuccessNotification,
});

const mapDispatchToProps = (dispatch: AppDispatch) => (
	{
		ChangeMapMode: (mode: IInteractionMode) => dispatch(setMapInteractionMode(mode)),
		ClearAuthErrors: () => dispatch(clearAuthError()),
		ClearAuthSuccess: makeDispatch(dispatch, clearAuthSuccess),
		ClearPostLoginProgressErrors: () => dispatch(clearPostLoginProgressError()),
		ClearManageableUsersError: () => dispatch(authSlice.actions.clearManageableUsersError()),
		ResetPostLoginProgress: () => dispatch(resetPostLoginProgress({})),
		ClearCluErrors: () => dispatch(clearCLUError()),
		ClearCropErrors: () => dispatch(clearCropsError()),
		ClearFileErrors: makeDispatch(dispatch, clearFileError),
		ClearGrowerErrors: () => dispatch(clearGrowerError()),
		ClearGrowerSuccess: () => dispatch(clearGrowerSuccess()),
		ClearManagmentZoneEditErrors: () => dispatch(clearManagmentZoneEditError()),
		ClearProductGameplanErrors: () => dispatch(clearProductGameplanError()),
		ClearSaveTreatmentErrors: makeDispatch(dispatch, clearSaveTreatmentError),
		ClearSeedAttrErrors: () => dispatch(clearSeedAttrError()),
		ClearSeedErrors: () => dispatch(clearSeedsError()),
		ClearSoilDataErrors: () => dispatch(clearSoilDataError()),
		ClearUiErrors: () => dispatch(clearUiError()),
		ClearUserAttrErrors: () => dispatch(clearUserAttrError()),
		ClearUserInfoErrors: () => dispatch(clearUserInfoError()),
		ClearBulkImportErrors: () => dispatch(clearBulkImportError()),
		ClearFieldPlanErrors: makeDispatch(dispatch, clearFieldPlanError),
		GetApplicationConfig: makeDispatch(dispatch, getApplicationConfig),
		SetDisplayDrawingTools: (display: boolean) => dispatch(toggleDrawingTools(display)),
		SetCurrentTheme: makeDispatch(dispatch, setCurrentTheme),
		ClearUserAdminSuccessNotification: () => dispatch(clearUserAdminSuccess()),
	}
);

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const Routing = connector(RoutingComponent);