import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputNumber } from 'antd';
import { IDiscountReasonCode } from '../../../logic/Models/Responses/DiscountReasonCodeResponse';
import { IPlanHybridDiscount } from '../../../logic/Models/Responses/CompetitiveAllowanceResponse';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { RoundAcres } from '../../../logic/Utility/CalculationUtilities';

const NumberInput = styled(InputNumber)`
	width: 100px;
	height: 25px;
	font-size: ${themeGet('fontSizes.normal')};
	margin-right: -2px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: right;
		height: auto;
		padding-right: 3px;
	}
`;
export interface IHybridDiscountRowProps
{
	isReadOnly: boolean;
	reasonCodes: IDiscountReasonCode[];
	hybridLevelDiscountItem: IPlanHybridDiscount;
	totalCropLevelDiscount: number;
	updateHybridRow: (hybridDiscountItem: IPlanHybridDiscount) => void;
}

export const HybridLevelDiscountRow = (props: IHybridDiscountRowProps) =>
{
	const { isReadOnly, hybridLevelDiscountItem, reasonCodes, totalCropLevelDiscount, updateHybridRow } = props;

	const theme = useTheme();

	const [reasonOptions, setReasonOptions] = useState<IOptionItem[]>([]);

	// Price without discounts
	const subtotal = useMemo(() => RoundAcres(hybridLevelDiscountItem?.Acres) * hybridLevelDiscountItem?.PricePerAcre, [hybridLevelDiscountItem]);

	// Total discount - discount price * acres
	const totalDiscount = useMemo(() =>
	{
		let hybridDiscount = hybridLevelDiscountItem?.PerAcreDiscount;
		// No discount total if there is not a Reason code or a discount price
		if (!hybridLevelDiscountItem.DiscountReasonCode || hybridLevelDiscountItem.PerAcreDiscount === 0)
		{
			hybridDiscount = totalCropLevelDiscount ?? 0;
		}
		// A reason code that is not available in the list of reason codes
		else if (reasonCodes.every(rc => rc.Id !== hybridLevelDiscountItem.DiscountReasonCode))
		{
			hybridDiscount = totalCropLevelDiscount ?? 0;
		}
		else
		{
			hybridDiscount += totalCropLevelDiscount;
		}

		const allDiscountsTotal = hybridDiscount * RoundAcres(hybridLevelDiscountItem?.Acres);
		if (allDiscountsTotal > subtotal)
		{
			return subtotal ?? 0;
		}
		return allDiscountsTotal ?? 0;
	}, [hybridLevelDiscountItem, totalCropLevelDiscount]);

	// Total
	const total = useMemo(() =>
	{
		return (subtotal - totalDiscount) ?? 0;
	}, [hybridLevelDiscountItem, totalCropLevelDiscount]);

	useEffect(() =>
	{
		if (reasonCodes)
		{
			const createReasonCodeOptions = [];

			// Add an empty value
			createReasonCodeOptions.push(
				{
					label: 'Select a Reason',
					value: ''
				});
			
			reasonCodes.forEach(reasonItem =>
			{
				const brandOption: IOptionItem =
				{
					label: reasonItem.Code,
					value: reasonItem.Id
				};
		
				createReasonCodeOptions.push(brandOption);
			});
		
			setReasonOptions([...createReasonCodeOptions]);
		}
	},[reasonCodes]);

	const onChangeReason = (value: string) =>
	{
		if (value === hybridLevelDiscountItem.DiscountReasonCode)
		{
			// Don't update if there is no change
			return;
		}
		
		hybridLevelDiscountItem.DiscountReasonCode = value;
		updateHybridRow(hybridLevelDiscountItem);
	};

	const onChangeDiscount = (value: number) =>
	{
		if (value === hybridLevelDiscountItem.PerAcreDiscount)
		{
			// Don't update if there is no change
			return;
		}

		if (value < 0)
		{
			value = 0;
		}
		if ((value + totalCropLevelDiscount) > hybridLevelDiscountItem.PricePerAcre)
		{
			value = hybridLevelDiscountItem.PricePerAcre;
		}

		hybridLevelDiscountItem.PerAcreDiscount = value;
		
		updateHybridRow(hybridLevelDiscountItem);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginTop: 5,
				marginBottom: 2,
				paddingRight: 15,
				paddingLeft: 15,
				paddingTop: 5,
				paddingBottom: 7,
				borderStyle: 'solid',
				borderRadius: 4,
				borderWidth: 1,
				borderColor: theme.colors.lightGrey,
				width: '100%',
				fontWeight: theme.fontWeights.bold,
				fontSize: theme.fontSizes.small,
				textAlign: 'right'
			}}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '20%', fontWeight: theme.fontWeights.normal, textAlign: 'left' }}>
				<div style={{ fontSize: theme.fontSizes.small }}>{`${hybridLevelDiscountItem.HybridName} ${hybridLevelDiscountItem.TraitIdentityName}`}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '5%' }}>
				<div>{RoundAcres(hybridLevelDiscountItem.Acres)}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
				<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(hybridLevelDiscountItem.PricePerAcre)}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '5%' }}>
				<div>{hybridLevelDiscountItem.Quantity}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
				<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(subtotal)}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<div style={{ marginRight: 5 }}>{isReadOnly ? '' : '$'}</div>
					{
						isReadOnly ? 
							<div>{hybridLevelDiscountItem.PerAcreDiscount !== undefined ? 
								Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(hybridLevelDiscountItem.PerAcreDiscount)
								: '$0.00'}</div>
							:
							<NumberInput
								style={{
									width: '75%',
									fontSize: theme.fontSizes.small,
									top: 1,
									borderColor: hybridLevelDiscountItem.PerAcreDiscount === 0 
								&& !!hybridLevelDiscountItem.DiscountReasonCode ? theme.colors.availabilityRed : theme.colors.lightestGrey,
								}}
								className='CropLevel_Discount'
								value={hybridLevelDiscountItem.PerAcreDiscount !== undefined ? hybridLevelDiscountItem.PerAcreDiscount : ''}
								step={0.05}
								min={0}
								max={hybridLevelDiscountItem.PricePerAcre}
								onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
									onChangeDiscount(evt.currentTarget.value ? Number(evt.currentTarget.value) : hybridLevelDiscountItem.PerAcreDiscount)}
								onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => 
									onChangeDiscount(evt.target && (evt.target as HTMLInputElement).value ? 
										Number((evt.target as HTMLInputElement).value) : hybridLevelDiscountItem.PerAcreDiscount)}
							/>
					}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '25%',
					alignItems: 'flex-end'
				}}
			>
				{
					isReadOnly ?
						<div>{reasonCodes.find(c => c.Id === hybridLevelDiscountItem.DiscountReasonCode)?.Code ?? 'None'}</div>
						:
						<Dropdown
							className='CropLevel_ReasonCode'
							variant='outlinednarrownopad'
							options={reasonOptions}
							onSelect={onChangeReason}
							selected={hybridLevelDiscountItem.DiscountReasonCode}
							containerWidth={'80%'}
							titleFontSize={theme.fontSizes.extraSmall}
							relativePosition={true}
							showErrorBorder={hybridLevelDiscountItem.PerAcreDiscount > 0 && (!hybridLevelDiscountItem.DiscountReasonCode || hybridLevelDiscountItem.DiscountReasonCode === '')}
							errorBorderColor={theme.colors.availabilityRed}
						/>
				}
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '12%' }}>
				<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalDiscount)}</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', width: '10%' }}>
				<div>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total ? total : 0)}</div>
			</div>
		</div>
	);
};