import {  } from '@reduxjs/toolkit';
import { feature, Feature, FeatureCollection, featureCollection, Geometry, LineString, multiPolygon, MultiPolygon, Polygon, Position } from '@turf/turf';
import { IZoneData } from '../../../pages/FieldActivities/VRS/VRSMain';
import { globalSession } from '../../../tracing/session';
import { createTracedAsyncThunk } from '../../../tracing/trace';
import { AppDispatch, RootState } from '../Store';
import { IZoneFeatureCollection } from './ManagementZoneSlice';

export interface ICutPolygonsRequest
{
	cutLine: LineString,
	zones: IZoneFeatureCollection
}

interface ICutPolygonsResponse
{
	id: string;
	geometry: Polygon;
}

export const cutPolygons = createTracedAsyncThunk<FeatureCollection<MultiPolygon, { zone: number }>, ICutPolygonsRequest, { dispatch: AppDispatch, state: RootState }>(
	'managementZones/cut',
	async (context, request, thunkAPI) =>
	{
		try
		{
			const { cutLine, zones } = request;
			const { config, auth, managementZoneEdits } = thunkAPI.getState();

			const geometryEditorUrl = config.GeometryEditor;

			const response = await fetch(`${geometryEditorUrl}/api/1/geometry/split?format=geojson`, {
				method: 'POST',
				body: JSON.stringify({
					cut: cutLine,
					geometries: zones.features.map((poly: Feature<Geometry | MultiPolygon, IZoneData>, idx: number) =>
					{
						return {
							id: String(poly.properties?.ZoneName ?? `autogenerated-${idx}`),
							geometry: poly.geometry
						};
					})
				}),
				headers: {
					Authorization: `Bearer ${auth.userAuthToken}`,
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok)
			{
				return thunkAPI.rejectWithValue(await response.text());
			}

			const data: ICutPolygonsResponse[] = await response.json();

			// Split the data by zone
			const irregularFeatures: FeatureCollection<Polygon, { zone: number }> = featureCollection(
				data.map((item: ICutPolygonsResponse) => feature(item.geometry, { zone: Number(item.id.split('/')[0]) })));

			const resultsByZone = normalizeZoneData(irregularFeatures);
			
			return resultsByZone;
		}
		// Likely a NetError thrown from the Api class
		catch (e)
		{
			return thunkAPI.rejectWithValue(e.message);
		}
	}
);

/**
 * There can be multiple polygons per zone returned here, so we need to get them all together into a multipolygon feature collection
 *  */
const normalizeZoneData = (irregularFeatures: FeatureCollection<Polygon, { zone: number }>) =>
{
	const consolidatedByZone: { [key: string]: { polygons: Position[][][], zone: number } } = {};
	irregularFeatures.features.forEach((polygon: Feature<Polygon, { zone: number }>) =>
	{
		if (!polygon.geometry)
		{
			return;
		}

		const zone = polygon.properties?.zone;

		if (!consolidatedByZone[zone])
		{
			consolidatedByZone[zone] = {
				polygons: [],
				zone: polygon.properties?.zone
			};
		}
		
		if (polygon.geometry.type === 'Polygon') 
		{
			consolidatedByZone[zone].polygons.push(polygon.geometry.coordinates);
		}
		else 
		{
			throw new Error(`Unexpected geometry in cluster: ${(polygon.geometry as any).type}`);
		}
	});

	const polysByZone = Object.values(consolidatedByZone);

	if (!polysByZone)
	{
		globalSession.error('No consolidated polygons to normalize.');
	}

	const normalizedCluster: FeatureCollection<MultiPolygon, { zone: number }> = featureCollection(polysByZone.map((zonePolys) =>
		multiPolygon(zonePolys.polygons, { zone: zonePolys.zone })));
	
	return normalizedCluster;
};